import {connect} from 'react-redux'
import {Layout} from "./Layout";
import {appAction} from "../../redux/actions/appAction";
import {languageActions} from "../../redux/actions/languageAction";
import {Message} from "../../constants/socket-message";
import {loginActions} from "../../redux/actions/loginAction";
import {liveTileAction} from "../../redux/actions/liveTileAction";
import {dashboardAction} from "../../redux/actions/dashboard";

const mapStateToProps = state => ({
    state: state,
    openSideBar: state.appReducer.openSideBar,
    audio: state.appReducer.audio,
    liveViewIsOpen: state.appReducer.liveViewIsOpen,
    languageOptions: state.languageReducer.languageOptions,
    // selectedLanguage: state.languageReducer.languageOptions.find(op => op.value === state.languageReducer.locale),
    locale: state.languageReducer.locale,
    loading: state.appReducer.loading,
    isReconnect: state.appReducer.isReconnect,
    overRetry: state.appReducer.overRetry,
    hideOverRetry: state.appReducer.hideOverRetry,
    liveTile: state.appReducer.liveTile,
    isSaved: state.appReducer.isSaved,
    // mailUnread: state.ccAdminInboxMessageReducer.unread,
    shouldLogout: state.appReducer.shouldLogout,
    alreadyOpen: state.appReducer.alreadyOpen,
    // hasPending: state.transferPatientReducer.hasPending,
    versions: state.appReducer.versions
});

function mapDispatchToProps(dispatch) {
    return ({
        handleLogout: () => dispatch(appAction.logout()),
        setLiveTile: (view) => dispatch(appAction.setLiveTile(view)),
        pollingByBedStatus: () => dispatch(dashboardAction.pollingByBedStatus()),
        reconnect: (isReconnect, isOnline) => dispatch(appAction.reconnect(isReconnect, isOnline)),
        setHideOverRetry: (hide) => dispatch(appAction.setHideOverRetry(hide)),
        setOverRetry: (show) => dispatch(appAction.setOverRetry(show)),
        closeLoading: () => dispatch(appAction.closeLoading()),
        setAlreadyOpen: (already) => dispatch(appAction.setAlreadyOpen(already)),
        changeLanguage: (lang) => dispatch(languageActions.changeLanguage(lang)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);