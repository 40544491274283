import {connect} from 'react-redux'
import {Schedule} from "./Schedule";
import {cspSchedule} from "../../../redux/actions/csp/cspSchedule";
import {cspManagement} from "../../../redux/actions/csp/cspManagement";

const mapStateToProps = state => ({
    listOfSchedule: state.cspScheduleReducer.listOfSchedule || [],
    cc_map: state.cspManagementReducer.cc_map || {}
});

function mapDispatchToProps(dispatch) {
    return ({
        listSchedule: () => dispatch(cspSchedule.listSchedule()),
        updateSchedule: (info) => dispatch(cspSchedule.updateSchedule(info)),
        removeUpdatedSchedule: (info) => dispatch(cspSchedule.removeUpdatedSchedule(info)),
        getListOfCC: () => dispatch(cspManagement.getListOfCC()),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);