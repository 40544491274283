import {zoom} from "./utils/width";

(async function(){
    function scale() {
        // const scale = window.outerWidth / 1920;
        // document.getElementsByTagName("BODY")[0].style.zoom = scale;
        // $('head').append('<meta name="viewport" content="width=1900, initial-scale=' + 1.0 + ', user-scalable=1e=1" >');
        zoom();
        // $('head').append('<meta name="viewport" content="width=device-width, initial-scale=0.47, user-scalable=1" >');
        // const viewport = document.querySelector("meta[name=viewport]");
        // viewport.setAttribute('content', 'width=device-width, initial-scale=' + 1.0 + ', maximum-scale=1.0, minimum-scale=1.0, user-scalable=yes, target-densityDpi=device-dpi');
    }

    window.addEventListener("orientationchange", function() {
        window.location.reload(false);
        // scale();
    }, false);

    window.addEventListener("resize", function() {
        // window.location.reload(false);
        scale();
    }, false);

    scale();
}());
