import {
    AUDIO_ON_OFF,
    DELETE_OLD_LIVE_TILE, GET_IMPORT_DATA, HIDE_OVER_RETRY,
    LOADING,
    PLAY_ALERT,
    RECONNECT, SET_ALREADY_OPEN,
    SET_LIVE_TILE, SET_OVER_RETRY, SET_VERSION, SHOULD_LOGOUT,
    TOGGLE_SIDEBAR
} from "../actions/appAction";
import $ from "jquery";

const initialState = {
    openSideBar: true,
    audio: true,
    liveViewIsOpen: true,
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return Object.assign({}, state, {openSideBar: !state.openSideBar});
        case AUDIO_ON_OFF:
            return Object.assign({}, state, {audio: !state.audio});
        case PLAY_ALERT:
            return Object.assign({}, state, {audio: action.payload});
        case LOADING:
            if (state.loading !== action.payload) {
                return Object.assign({}, state, {loading: action.payload});
            }
            return Object.assign({}, state,);
        case RECONNECT:
            if (state.isReconnect === action.payload) {
                return state;
            }
            return Object.assign({}, state, {isReconnect: action.payload});
        case DELETE_OLD_LIVE_TILE:
            return Object.assign({}, state, {closeLiveTile: action.payload});
        case SET_LIVE_TILE:
            return Object.assign({}, state, {liveTile: action.payload});
        case SET_OVER_RETRY:
            if (state.overRetry === action.payload) return state;
            return Object.assign({}, state, {overRetry: action.payload});
        case HIDE_OVER_RETRY:
            if (state.hideOverRetry === action.payload) return state;
            return Object.assign({}, state, {hideOverRetry: action.payload});
        case GET_IMPORT_DATA:
            return Object.assign({}, state, {...action.payload});
        case SHOULD_LOGOUT:
            return Object.assign({}, state, {shouldLogout: true});
        case SET_ALREADY_OPEN:
            return Object.assign({}, state, {alreadyOpen: action.payload});
        case SET_VERSION:
            return Object.assign({}, state, {versions: action.payload});
        default:
            return state
    }
};

export default appReducer;