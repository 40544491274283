import {Configs} from "../constants/configs";

export const validateMatMac = (mac) => {
    if (!mac || mac === "" || typeof mac !== "string") return false;

    const regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;

    return regex.test(mac);
};

export const checkNotEmpty = (str) => {
    return !(!str || str === "");
};

export function isCompatible(current, latest) {
    if (!current || !latest) return false;

    try {
        const splitV1 = current.split(".");
        const splitV2 = latest.split(".");

        if (Number(splitV1[0]) !== Number(splitV2[0])) return false;

        if (Number(splitV1[1]) !== Number(splitV2[1])) return false;

        // return Number(splitV1[2]) >= Number(splitV2[2]);
        return true;
    } catch (e) {
        return false;
    }
}

export function isCompatibleFw(current, latest) {
    if (!current || !latest) return false;

    try {
        const splitV1 = current.split(".");
        const splitV2 = latest.split(".");

        if (Number(splitV1[0]) !== Number(splitV2[0])) return false;

        // if (Number(splitV1[1]) !== Number(splitV2[1])) return false;

        // return Number(splitV1[2]) >= Number(splitV2[2]);
        return true;
    } catch (e) {
        return false;
    }
}

export const validateRange = (name, val, lowSp02) => {
    switch (name) {
        case "high_resp":
            return val >= 40 && val <= 90;
        case "low_sp02":
            return Configs.APP_TYPE === 1 || (val >= 70 && val <= 95);
        case "high_sp02":
            return Configs.APP_TYPE === 1 || (val >= Number(lowSp02) + 2 && val <= 100);
        case "low_pr_threshold":
            return Configs.APP_TYPE === 1 || (val >= 50 && val <= 90);
        case "high_pr_threshold":
            return Configs.APP_TYPE === 1 || (val >= 130 && val <= 200);
        case "low_temperature":
            return Configs.APP_TYPE === 1 || (val >= 36.0 && val <= 36.9);
        case "high_temperature":
            return Configs.APP_TYPE === 1 || (val >= 37.5 && val <= 39.0);
        default:
            return true;
    }
};

export const isValidEmail = (email) => {
    if (!email || email === "" || typeof email !== "string") return false;

    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    return regex.test(email);
};