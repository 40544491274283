import React, {useState} from "react";

import {EspDropdown} from "../EspDropdown";
import "./OACPagingSelection.css";

export function OACPagingSelection(props) {

    const [state, setState] = useState({selected: {value: 10, label: 10}});

    const handleChangePage = (selected) => {
        setState({...state, selected: selected});
        props.handleChangePage(selected.value);
    };

    const options = [5, 10];

    return <div className="oac-paging-selection">
        {
            props.isSearchable
                ? <EspDropdown
                    key={Math.random()}
                    value={state.selected}
                    placeholder={`Select`}
                    options={
                        options.map(n => ({
                            value: n,
                            label: n
                        }))
                    }
                    onChange={handleChangePage}
                />
                : <EspDropdown
                    key={Math.random()}
                    value={state.selected}
                    placeholder={`Select`}
                    options={
                        options.map(n => ({
                            value: n,
                            label: n
                        }))
                    }
                    onChange={handleChangePage}
                />
        }
    </div>
}