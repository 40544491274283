import {dataProvider} from "../../connections/net";
import {appAction} from "./appAction";

export const SET_ERROR = "transferPatientAction/action/SET_ERROR";
export const SET_STATE = "transferPatientAction/action/SET_STATE";
export const GET_HISTORY = "transferPatientAction/action/GET_HISTORY";
export const GET_BEDS = "transferPatientAction/action/GET_BEDS";
export const CLEAR_DATA = "transferPatientAction/action/CLEAR_DATA";

export const transferPatientAction = {
    getListAssignedBeds: () => async dispatch => {
        try {
            const {status_code, oah} = await dataProvider.getListAssignedBeds();
            if (status_code === 0 && oah) {
                dispatch({type: GET_BEDS, payload: oah});
            }
        } catch (e) {
            console.log("Error: ", e.message);
            // dispatch(appAction.reconnect(true))
        }
    },
    getTransferHistory: () => async dispatch => {
        try {
            const {status_code, history} = await dataProvider.getTransferHistory();
            if (status_code === 0 && history) {
                dispatch({type: GET_HISTORY, payload: history});
                return true;
            }
            return false;
        } catch (e) {
            console.log("Error: ", e.message);
            return false;
        }
    },
    transferPatient: (info) => async dispatch => {
       try {
           const {status_code, oah, error} = await dataProvider.transferPatient(info);
           if (status_code === 0 && oah) {
               dispatch({type: GET_BEDS, payload: oah});
           } else {
               dispatch({type: SET_ERROR, payload: error})
           }
       } catch (e) {
           console.log("Error: ", e.message);
       }
    },
    setError: (error) => dispatch => dispatch({type: SET_ERROR, payload: error}),
    setState: (state) => dispatch => dispatch({type: SET_STATE, payload: state}),
    clearData: () => dispatch => dispatch({type: CLEAR_DATA})
};