import {dataProvider} from "../../connections/net";

export const SET_ERROR = "dischargeAction/action/SET_ERROR";
export const SET_STATE = "dischargeAction/action/SET_STATE";
export const GET_HISTORY = "dischargeAction/action/GET_HISTORY";
export const CLEAR_DATA = "dischargeAction/action/CLEAR_DATA";

export const dischargeAction = {
    getDischargeHistory: () => async dispatch => {
        try {
            const {status_code, history} = await dataProvider.getDischargeHistory();
            if (status_code === 0 && history) {
                dispatch({type: GET_HISTORY, payload: history});
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
    },
    dischargePatient: (nurse) => async dispatch => {

    },
    setError: (error) => dispatch => dispatch({type: SET_ERROR, payload: error}),
    setState: (state) => dispatch => dispatch({type: SET_STATE, payload: state}),
    clearData: () => dispatch => dispatch({type: CLEAR_DATA})
};