import React, {useState} from "react";

import "./OACPaging.css";
import leftArrow from "../../../images/oac/left-arrow.png";
import {messageWrapper as t} from "../../../utils/message-wrapper";
import rightArrow from "../../../images/oac/right-arrow.png";

export function OACPaging(props) {

    let {total, page, itemsPerPage} = props;

    if(!total) total = 0;

    const handleGo = (num) => {
        props.setPage(page + num);
    };

    const languageClazz = localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") === "ja" ? " ja" : "";

    const pageToPage = `${(page - 1) * itemsPerPage + 1}-${(page - 1) * itemsPerPage + itemsPerPage > total ? total: (page - 1) * itemsPerPage + itemsPerPage}`;

    return <div className={props.clazz ? "oac-paging " + props.clazz + languageClazz : "oac-paging" + languageClazz}>
        <img onClick={() => handleGo(-1)} className={"small-icon" + (page === 1 ? " disabled" : "")} src={leftArrow} />
        <span>{t(`Displaying {0} of {1}`, {0: pageToPage, 1: total})}</span>
        <img onClick={() => handleGo(1)} className={"small-icon" + ((page - 1) * itemsPerPage + itemsPerPage >= total ? " disabled" : "")} src={rightArrow} />
    </div>
}