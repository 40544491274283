import React, {useState, useRef} from "react";
import Calendar from "react-calendar";
import moment from "moment";
import 'react-calendar/dist/Calendar.css';
import './OmniCalendar.css';
import {useOutsideDetect} from "../../utils/hooks";
import {formatDisplayDate} from "../../utils/format";
import CalendarImg from "../../images/CalendarImg.png";

export function InputDatePicker(props) {
    const [state, setState] = useState({});
    const dateRef = useRef(null);

    useOutsideDetect(dateRef, state.isOpenCalendar, () => setState({...state, isOpenCalendar: false}));

    const tileDisabled = ({activeStartDate, date, view}) => {
        const formatted1 = moment().format("YYYYMMDD");
        const formatted2 = moment(date).format("YYYYMMDD");
        const formatted3 = props.minDate ? moment(props.minDate).format("YYYYMMDD") : null;

        if(props.showAll) return moment(formatted3).diff(moment(formatted2), 'days') > 0;

        let startBorder = true;
        if (formatted3) {
            startBorder = moment(formatted3).diff(moment(formatted2), 'days') > 0;
        }
        const endBorder = moment(formatted1).diff(moment(formatted2), 'days') < 0;

        return startBorder || endBorder;
    };

    return <>
        <div ref={dateRef} className="inline">
            <input onFocus={() => setState({...state, isOpenCalendar: true})} type="text"
                   className={props.className}
                   value={formatDisplayDate(props.value || new Date())}/>
            {
                state.isOpenCalendar
                    ? <div className="calendar" tabIndex="1">
                        <Calendar
                            onChange={(value) => {
                                setState({...state, isOpenCalendar: false});
                                props.onChange(value);
                            }}
                            value={props.value}
                            tileDisabled={tileDisabled}
                            locale={
                                (localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") !== "en") ? "en" : "en-sg"
                            }
                        />
                    </div>
                    : ""
            }
        </div>
        {
            !props.hideImage
             ? <img src={CalendarImg} onClick={() => setState({...state, isOpenCalendar: true})}/>
                : undefined
        }
    </>
}