import {dataProvider} from "../../connections/net";

export const SET_ERROR = "enrollAction/action/SET_ERROR";
export const SET_STATE = "enrollAction/action/SET_STATE";
export const GET_AVAILABLE_BED = "enrollAction/action/GET_AVAILABLE_BED";
export const CLEAR_DATA = "enrollAction/action/CLEAR_DATA";

const retry = {};

export const enrollAction = {
    getListOfAvailableBeds: () => async dispatch => {
        try {
            const {status_code, oah} = await dataProvider.getAvailableBeds();
            if (status_code === 0 && oah) {
                dispatch({type: GET_AVAILABLE_BED, payload: oah});
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
    },
    enrollPatient: (patient) => async dispatch => {
        try {
            const {status_code, error} = await dataProvider.enrollPatient(patient);
            if (status_code === 0) {
                dispatch(enrollAction.getListOfAvailableBeds());
                delete retry[patient.bed_id];
                return true;
            } else {
                if (error === "RECORD_OUTDATED" && !retry[patient.bed_id]) {
                    // retry get latest information;
                    const {status_code: status_2, oah} = await dataProvider.getAvailableBeds();
                    if (status_2 === 0) {
                        const {beds} = oah;
                        const foundBed = beds.find(b => b.id === patient.bed_id);
                        if (foundBed) {
                            patient.bed_timestamp = foundBed.last_update;
                            retry[patient.bed_id] = true;
                            return dispatch(enrollAction.enrollPatient(patient));
                        }
                    }
                }

                dispatch(enrollAction.setError(error));
                return false;
            }
        } catch (e) {
            console.log("Error:", e.message);
            return false;
        }
    },
    setError: (error) => dispatch => dispatch({type: SET_ERROR, payload: error}),
    setState: (state) => dispatch => dispatch({type: SET_STATE, payload: state}),
    clearData: () => dispatch => dispatch({type: CLEAR_DATA})
};