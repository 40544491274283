import {authProvider, dataProvider} from "../../connections/net";
import {ROLES} from "../../constants/roles";
import {appAction} from "./appAction";
import {Password} from "../../constants/default-password";

export const DO_LOGIN_SUCCESS = "login/action/DO_LOGIN_SUCCESS";
export const DO_LOGIN_ERROR = "login/action/DO_LOGIN_ERROR";
export const CHANGE_PASSWORD = "login/action/CHANGE_PASSWORD";
export const SET_ERROR = "login/action/SET_ERROR";
export const CLEAR_DATA = "login/action/CLEAR_DATA";
export const GET_COUNTRY_CODE = "login/action/GET_COUNTRY_CODE";
export const DUPLICATE_LOGIN_RESPONSE = "login/action/DUPLICATE_LOGIN_RESPONSE";
export const DO_RESET_PASSWORD = "login/action/DO_RESET_PASSWORD";

export const loginActions = {
    checkLogin: (data, pusher) => async dispatch => {
        try {
            // const loggedin = await authProvider.checkLogin({username: data.username, oah_user: "ADMIN"});
            const loggedin = await authProvider.checkLogin({username: data.username});
            if (false) {
                dispatch({type: DUPLICATE_LOGIN_RESPONSE, payload: true});
            } else {
                // dispatch(loginActions.doLogin({...data,  oah_user: "ADMIN"}, pusher));
                dispatch(loginActions.doLogin({...data,}, pusher));
            }
        } catch (e) {
            dispatch({type: DO_LOGIN_ERROR, payload: "unauthorized"});
        }
    },
    doLogin: (data, pusher) => async dispatch => {
        // localStorage.setItem("token", `Bearer test`);
        // localStorage.setItem("department", "test");
        // localStorage.setItem("OMNI2_ROLE", ROLES.CcAdmin);
        // localStorage.setItem("OMNI2_USER", "test");
        // localStorage.setItem("area", "test");
        // dispatch({type: DO_LOGIN_SUCCESS});
        // pusher("/cc-admin-dashboard");

        dispatch(appAction.openLoading());
        try {
            // const {role} = data;
            // localStorage.setItem("OMNI2_ROLE", role);
            const {token, role, department_id, area_id} = await authProvider.login(data);

            // set token
            dispatch(appAction.closeLoading());
            localStorage.setItem("token", `Bearer ${token}`);
            // localStorage.setItem("department", department_id);
            localStorage.setItem("OMNI2_ROLE", role);
            // localStorage.setItem("OMNI2_ROLE", ROLES.OspMgr);
            localStorage.setItem("OMNI2_USER", data.username);
            // localStorage.setItem("area", area_id);
            if (
                (role === ROLES.CcAdmin && data.password !== Password.CCAdmin)
                || (role === ROLES.NurseAdmin && data.password !== Password.NurseAdmin)
                || (role === ROLES.Nurse && data.password !== Password.Nurse)
            ) {
                // localStorage.setItem("OMNI2_NEED_CHANGE_PASSWORD", "true");
            }
            localStorage.removeItem("OMNI2_TILES");

            // save login
            dispatch({type: DO_LOGIN_SUCCESS});
            switch (role) {
                case ROLES.OSP:
                    window.location.href = "/osp-dashboard";
                    break;
                case ROLES.CSP:
                    window.location.href = "/csp-dashboard";
                    break;
                default:
                    pusher("/osp-dashboard");
            }
            // init data
            // dispatch(dashboardActions.init());Error:
        } catch (e) {
            // dispatch({type: DUPLICATE_LOGIN_RESPONSE, payload: true});
            // dispatch(appAction.closeLoading());
            // return;
            dispatch({type: DO_LOGIN_ERROR, payload: "unauthorized"});
            dispatch(appAction.closeLoading());
        }
    },
    changePassword: (currentPassword, newPassword) => async dispatch => {
        const update = {
            username: localStorage.getItem("OMNI2_USER"),
            password: newPassword,
            current_password: currentPassword,
        };
        let {status_code, error} = await dataProvider.updateOwnPassword(update);
        if (status_code !== 0) {
            if (error === "ERROR_COMMON#UNAUTHORIZE") {
                error = "ERROR#INVALID_CURRENT_PASSWORD";
            }
            dispatch({type: CHANGE_PASSWORD, payload: {success: false, error: error}});
            return false;
        } else {
            localStorage.setItem("OMNI2_NEED_CHANGE_PASSWORD", "true");
            dispatch(loginActions.clearData());
            return true;
        }
    },
    clearData: () => dispatch => dispatch({type: CLEAR_DATA}),
    duplicateLoginResponse: (response) => dispatch => {
        dispatch({type: DUPLICATE_LOGIN_RESPONSE, payload: response});
    },
    // getCountryCode: () => async dispatch => {
    //     const country_code = localStorage.getItem("ESP_COUNTRY_CODE");
    //     // console.log(country_code)
    //     if (country_code && country_code !== "") {
    //         dispatch({type: GET_COUNTRY_CODE, payload: {country_code}});
    //         return;
    //     }
    //     const res = await dataProvider.getCountryCode();
    //     localStorage.setItem("ESP_COUNTRY_CODE", res.country_code);
    //     dispatch({type: GET_COUNTRY_CODE, payload: {country_code: res.country_code}});
    // },
    // setCountryCode: (countryCode) => async dispatch => {
    //     dispatch({type: GET_COUNTRY_CODE, payload: {country_code: countryCode}});
    // },
    setError: (error) => dispatch => dispatch({type: DO_LOGIN_ERROR, payload: error}),
    resetPassword: () => async dispatch => {
        const {status_code, error} = await dataProvider.resetPassword();
        if (status_code === 0) {
            return true;
        }
        dispatch(loginActions.setError(error));
        return false;
    },
    requestResetPassword: (info) => async dispatch => {
        const {status_code, error} = await dataProvider.requestResetPassword(info);
        if (status_code === 0) {
            return true;
        }
        dispatch(loginActions.setError(error));
        return false;
    },
    doResetPassword: (info) => async dispatch => {
        const {status_code, error, email, role} = await dataProvider.doResetPassword(info);

        if (status_code === 0) {
            dispatch({type: DO_RESET_PASSWORD, payload: {email, role}});
            return true;
        }
        dispatch(loginActions.setError(error));
        return false;
    }
};