import {Host} from "../constants/host";

const apiUrlProxy = Host.LocalProxy || window.location.protocol + "//" + window.location.hostname + ":" + Host.LocalProxyPort;

const apiUrl = "https://api.oac-system.com";

const apiPath = {
    Login: "api/user/login",
    CheckLogin: "api/user/is_login",
    Logout: "api/user/logout",

    GetOAHData: "api/oah/get_oah_data",
    GetNurseQrCode: "api/admin/get_nurse_qr",
    GetListBedAssignment: "api/oah/query_bed_function_status",
    AddNurse: "api/manager/add_nurse_account",
    UpdateNurse: "api/manager/update_nurse_account",
    RemoveNurse: "api/manager/remove_nurse_account",
    TransferPatient: "api/admin/transfer_patient",
    TransferHistory: "api/admin/transfer_history",
    EnrollPatient: "api/admin/enroll_patient_record",
    DischargePatient: "api/admin/discharge_patient_record",
    DischargeHistory: "api/admin/discharge_history",
    UpdateThresholdSetting: "api/data/set_friendly_name_threshold",
    GetDataForReport: "api/data/get_sleeplog",
    GetDataForLiveView: "api/data/live_view",
    EditPatient: "api/admin/edit_patient",
    ClearAlarm: "api/data/clear_alarm",

    // osp-mgr
    AssignBed: "api/manager/assign_nurse_to_bed",
    UnassignBed: "api/manager/assign_nurse_to_bed",
    GetTechQrCode: "api/manager/get_tech_qr",
    GetWifiEncryptionList: "api/manager/get_wifi_encryption",
    AddWifi: "api/manager/add_wifi",
    DeleteWifi: "api/manager/delete_wifi",
    AddCU: "api/manager/add_bed_name",
    EditCU: "api/manager/edit_bed_name",
    DeleteCU: "api/manager/delete_bed_name",
    UnbindCU: "api/manager/unbind_bcu",
    GetAddedWifi: "api/manager/get_wifi_list",
    UpdateNurseAdminPassword: "api/manager/update_nurse_admin_password",
    AuthCheck: "api/user/auth_check",
    ResetPassword: "api/manager/reset_password",
    RequestResetPassword: "api/user/send_reset_password_email",
    DoResetPassword: "api/user/verify_reset_password",

    // csp
    ListCC: "api/csp/list_oah",
    ListDiagnostics: "api/csp/list_diagnostics",
    ListSchedule: "api/csp/list_schedule",
    UpdateSchedule: "api/csp/schedule_update",
    RemoveUpdatedSchedule: "api/csp/remove_update_schedule",
    ListWarranty: "api/csp/list_warranty_by_oah_id",
    CreateCC: "api/csp/create_oah",
    DeleteCC: "api/csp/delete_oah",
    UpdateCC: "api/csp/update_oah",
    GetListOfCountry: "api/csp/list_country",

    // everyone
    // GetVersions: "api/system/get_version_detail",
};

const PostOptions = {
    method: "POST",
    headers: {
        "Content-Type": "application/json"
    }
};

export const authProvider = {
    checkAuth: () => localStorage.getItem('token')
        ? Promise.resolve()
        : Promise.reject(),
    logout: async () => {
        localStorage.removeItem('token');
        localStorage.removeItem('department');
        localStorage.removeItem('OMNI2_ROLE');
        localStorage.removeItem('OMNI2_USER');
        localStorage.removeItem('OMNI2_NEED_CHANGE_PASSWORD');
        localStorage.removeItem('OMNI2_TILES_OPENING');

        await fetchHandleError(
            `${apiUrl}/${apiPath.Logout}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
            }
        ).then(res => {
        });

        return Promise.resolve();
    },
    logoutWithoutRequest: async () => {
        localStorage.removeItem('token');
        localStorage.removeItem('department');
        localStorage.removeItem('OMNI2_ROLE');
        localStorage.removeItem('OMNI2_USER');
        localStorage.removeItem('OMNI2_NEED_CHANGE_PASSWORD');
        localStorage.removeItem('OMNI2_TILES_OPENING');
        return Promise.resolve();
    },
    login: async (data) => {
        try {
            const result = await fetchHandleError(
                `${apiUrl}/${apiPath.Login}`,
                {...PostOptions, body: JSON.stringify(data)}
            ).then(res => res.json());
            const {token, role, status_code, department_id, area_id, error_message, message} = result;
            if (status_code !== 0) {
                return Promise.reject(error_message || message);
            }
            return Promise.resolve({token, role, department_id, area_id});
        } catch (e) {
            console.log(e);
            return Promise.reject(e);
        }
    },
    checkLogin: async (user) => {
        try {
            const result = await fetchHandleError(
                `${apiUrl}/${apiPath.CheckLogin}`,
                {...PostOptions, body: JSON.stringify(user)}
            ).then(res => res.json());
            const {status_code, loggedin, error} = result;
            if (status_code !== 0) {
                return Promise.reject(error);
            }
            return Promise.resolve(loggedin);
        } catch (e) {
            console.log(e);
            return Promise.reject(e);
        }
    }
};

export const dataProvider = {

    getBedAllocation: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.GetOAHData}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
            })
            .then(res => res.json());
    },
    getBedAssignment: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.GetListBedAssignment}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
            })
            .then(res => res.json());
    },
    getListOfNurses: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.GetOAHData}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
            })
            .then(res => res.json());
    },
    getQRData: async (nurse) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.GetNurseQrCode}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(nurse)
            })
            .then(res => res.json());
    },
    addNurse: async (nurse) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.AddNurse}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(nurse)
            })
            .then(res => res.json());
    },
    removeNurse: async (nurse) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.RemoveNurse}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(nurse)
            })
            .then(res => res.json());
    },
    updateNurse: async (nurse) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.UpdateNurse}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(nurse)
            })
            .then(res => res.json());
    },
    assignBed: async (bed) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.AssignBed}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(bed)
            })
            .then(res => res.json());
    },
    getAvailableBeds: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.GetOAHData}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
            })
            .then(res => res.json());
    },
    enrollPatient: async (patient) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.EnrollPatient}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(patient)
            })
            .then(res => res.json());
    },
    getDischargeHistory: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.DischargeHistory}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                // body: JSON.stringify(patient)
            })
            .then(res => res.json());
    },
    getListAssignedBeds: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.GetOAHData}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                // body: JSON.stringify(patient)
            })
            .then(res => res.json());
    },
    getTransferHistory: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.TransferHistory}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                // body: JSON.stringify(patient)
            })
            .then(res => res.json());
    },
    transferPatient: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.TransferPatient}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    getSleepLog: async ({patient_id, days}) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.GetDataForReport}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({patient_id, days})
            })
            .then(res => res.json());
    },
    editPatient: async (update) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.EditPatient}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(update)
            })
            .then(res => res.json());
    },
    dischargePatient: async (patient) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.DischargePatient}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(patient)
            })
            .then(res => res.json());
    },
    getThresholdSetting: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.GetOAHData}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                // body: JSON.stringify(patient)
            })
            .then(res => res.json());
    },
    updateThresholdSetting: async (update) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.UpdateThresholdSetting}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(update)
            })
            .then(res => res.json());
    },
    pollingData: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.GetDataForLiveView}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                // body: JSON.stringify(update)
            })
            .then(res => res.json());
    },
    turnOffSoundForTile: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.ClearAlarm}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    getTechQRCode: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.GetTechQrCode}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                // body: JSON.stringify(update)
            })
            .then(res => res.json());
    },
    getWifiEncryptionList: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.GetWifiEncryptionList}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                // body: JSON.stringify(update)
            })
            .then(res => res.json());
    },
    getAddedWifi: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.GetAddedWifi}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                // body: JSON.stringify(update)
            })
            .then(res => res.json());
    },
    addWifi: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.AddWifi}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    deleteWifi: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.DeleteWifi}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    getListOfCU: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.GetOAHData}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                // body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    addCU: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.AddCU}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    editCU: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.EditCU}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    deleteCU: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.DeleteCU}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    unbindCU: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.UnbindCU}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    updateNurseAdminPassword: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.UpdateNurseAdminPassword}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    authCheck: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.AuthCheck}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                // body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    resetPassword: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.ResetPassword}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                // body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    requestResetPassword: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.RequestResetPassword}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    doResetPassword: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.DoResetPassword}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    listCC: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.ListCC}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                // body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    listDiagnostics: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.ListDiagnostics}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    listSchedule: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.ListSchedule}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    updateSchedule: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.UpdateSchedule}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    removeUpdatedSchedule: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.RemoveUpdatedSchedule}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    listWarrantyByOahId: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.ListWarranty}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    addCC: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.CreateCC}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    deleteCC: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.DeleteCC}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    updateCC: async (info) => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.UpdateCC}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
    getListOfCountry: async () => {
        return await fetchHandleError(
            `${apiUrl}/${apiPath.GetListOfCountry}`,
            {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                // body: JSON.stringify(info)
            })
            .then(res => res.json());
    },
};

const fetchHandleError = (...args) => {
    return fetch(args[0], args[1])
        .then(response => {
            // console.log(response.status);
            if (response.status === 401) throw new Error("Unauthorize");

            return response;
        })
        .catch(e => {
            // alert(e.message)
            if (e.message === "Failed to fetch" || e.message === "Load failed") {
                throw new Error("Disconnect");
            } else {
                throw e;
            }
        });
};

export const useAuth = () => {
    setImmediate(async () => {
        try {
            await authProvider.checkAuth();
        } catch (e) {
            window.location.replace("/login");
        }
    })
};

export const delay = async function (timeout) {
    return new Promise(res => {
        setTimeout(() => {
            res();
        }, timeout);
    });
};