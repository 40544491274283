export const compareMatName = (name1, name2) => {
    if (!name1 || !name2) return 0;

    const splitting1 = name1.split("-");
    const splitting2 = name2.split("-");

    // unreadable format
    if (!splitting1 || splitting1.length < 3 || !splitting2 || splitting2.length < 3) {
        return name1 < name2 ? 1 : -1;
    }

    // compare 1
    if(splitting1[0] !== splitting2[0]) return splitting1[0] > splitting2[0] ? 1: -1;

    // compare 2
    if(splitting1[1] !== splitting2[1]) return splitting1[1] > splitting2[1] ? 1: -1;

    // compare number
    const reg = /\d+$/;
    const test1 = splitting1[2].match(reg);
    const test2 = splitting2[2].match(reg);
    if (!test1 || !test2 || !test1[0] || !test2[0]) return splitting1[2] < splitting2[2];
    const s1 = test1[0];
    const s2 = test2[0];
    if (!isNaN(s1) && !isNaN(s2)) {
        return Number(s2) - Number(s1);
    } else {
        return s1 < s2 ? 1 : -1;
    }
};

export const compareOACBedName = (name1, name2) => {
    if (!name1 || !name2) return 0;

    let splitting1, splitting2;
    if (name1.indexOf("-") > -1) {
        splitting1 = name1.toUpperCase().split("-");
        splitting2 = name2.toUpperCase().split("-");
    } else if(name1.indexOf(" ") > -1) {
        splitting1 = name1.toUpperCase().split(" ");
        splitting2 = name2.toUpperCase().split(" ");
    } else if(name1.indexOf("#") > -1) {
        splitting1 = name1.toUpperCase().split("#");
        splitting2 = name2.toUpperCase().split("#");
    }

    // unreadable format
    if (!splitting1 || splitting1.length < 2 || !splitting2 || splitting2.length < 2) {
        return name1 < name2 ? 1 : -1;
    }

    // compare 1
    if(splitting1[0] !== splitting2[0]) return splitting1[0] > splitting2[0] ? 1: -1;

    // compare 2
    // if(splitting1[1] !== splitting2[1]) return splitting1[1] > splitting2[1] ? 1: -1;

    // compare number
    const reg = /\d+$/;
    const test1 = splitting1[1].match(reg);
    const test2 = splitting2[1].match(reg);
    if (!test1 || !test2 || !test1[0] || !test2[0]) return splitting1[1] < splitting2[1];
    const s1 = test1[0];
    const s2 = test2[0];
    if (!isNaN(s1) && !isNaN(s2)) {
        return Number(s1) - Number(s2);
    } else {
        return s1 > s2 ? 1 : -1;
    }
};