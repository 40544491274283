import {dataProvider} from "../../../connections/net";
import {appAction} from "../appAction";

export const GET_SCHEDULE_LIST = "cspSchedule/action/GET_SCHEDULE_LIST";
export const SET_ERROR = "cspSchedule/action/SET_ERROR";
export const SET_STATE = "cspSchedule/action/SET_STATE";
export const CLEAR_DATA = "cspSchedule/action/CLEAR_DATA";

export const cspSchedule = {
    listSchedule: (info) => async dispatch => {
        dispatch(appAction.openLoading());
        try {
            const {status_code, bed_list} = await dataProvider.listSchedule(info);
            if (status_code === 0 && bed_list) {
                dispatch({type: GET_SCHEDULE_LIST, payload: bed_list});
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
        dispatch(appAction.closeLoading());
    },
    updateSchedule: (info) => async dispatch => {
        dispatch(appAction.openLoading());
        let res;
        try {
            const {status_code, error} = await dataProvider.updateSchedule(info);
            if (status_code === 0) {
                dispatch(cspSchedule.listSchedule());
                res = true;
            } else {
                dispatch({type: SET_ERROR, payload: error});
                res = false;
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
        dispatch(appAction.closeLoading());
        return res;
    },
    removeUpdatedSchedule: (info) => async dispatch => {
        dispatch(appAction.openLoading());
        let res;
        try {
            const {status_code, error} = await dataProvider.removeUpdatedSchedule(info);
            if (status_code === 0) {
                dispatch(cspSchedule.listSchedule());
                dispatch({type: SET_ERROR, payload: undefined});
                res = true;
            } else {
                dispatch({type: SET_ERROR, payload: error});
                res = false;
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
        dispatch(appAction.closeLoading());
        return res;
    },
    setError: (error) => dispatch => dispatch({type: SET_ERROR, payload: error}),
    setState: (state) => dispatch => dispatch({type: SET_STATE, payload: state}),
    clearData: () => dispatch => dispatch({type: CLEAR_DATA})
};