import React, {useState, useEffect} from "react";
import audioURL from "../../audioes/serious1.mp3";

export function IrespAudio(props) {

    const handlePlay = () => {
        const audio = document.getElementById("myAudio");
        audio.play();
        audio.loop = true;
    };

    const handleStop = () => {
        const audio = document.getElementById("myAudio");
        audio.pause();
        audio.currentTime = 0;
    };

    return (
        <audio id="myAudio" loop>
            <source src={audioURL} type="audio/mpeg"/>
            Your browser does not support the audio element.
        </audio>
    )
}