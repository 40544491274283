import React from "react";
import {Link} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import cx from "classnames";
import {messageWrapper as t} from "../../utils/message-wrapper";
import {ROLES} from "../../constants/roles";
import {changeTheme} from "../../utils/theme";
import {Versions} from "../../constants/versions";
import "./modal.css";
import {Configs} from "../../constants/configs";

export function SideBar(props) {
    const location = useLocation();

    if (!props.isOpen) return <div/>;


    const renderOSPMenu = () => {
        changeTheme("blue");
        return <div className="sidebar-body">
            <div className={cx(
                "sidebar-item",
                location.pathname.indexOf("/osp-dashboard") > -1 ? "active" : ""
            )}>
                <Link to="/osp-dashboard">
                    <span className="sidebar-link-label">{t(`CSP Management`)}</span>
                </Link>
            </div>
            <div className="sidebar-group">
                <span className={cx(
                    "sidebar-link-label",
                    location.pathname.indexOf("/osp-maintenance") > -1 ? "active" : "",
                    location.pathname.indexOf("/osp-maintenance") > -1 ? "active" : ""
                )}>{t(`Maintenance`)}</span>
                <div className={cx(
                    "sidebar-item",
                    location.pathname.indexOf("/osp-maintenance-release") > -1 ? "active" : "",
                )}>
                    <Link to="/osp-maintenance-release">
                        <span className="sidebar-link-label">{t(`SW/FW Release`)}</span>
                    </Link>
                </div>
            </div>
        </div>
    };

    const renderCSPMenu = () => {
        changeTheme("red");
        return <div className="sidebar-body">
            <div className={cx(
                "sidebar-item",
                location.pathname.indexOf("/csp-dashboard") > -1 ? "active" : ""
            )}>
                <Link to="/csp-dashboard">
                    <span className="sidebar-link-label">{t(`Dashboard`)}</span>
                </Link>
            </div>
            <div className={cx(
                "sidebar-item",
                location.pathname.indexOf("/csp-cc-management") > -1 ? "active" : ""
            )}>
                <Link to="/csp-cc-management">
                    <span className="sidebar-link-label">{t(`Care Centre Management`)}</span>
                </Link>
            </div>
            {/*<div className="sidebar-group">*/}
                {/*<span className={cx(*/}
                    {/*"sidebar-link-label",*/}
                    {/*location.pathname.indexOf("/csp-maintenance") > -1 ? "active" : "",*/}
                    {/*location.pathname.indexOf("/csp-maintenance") > -1 ? "active" : ""*/}
                {/*)}>{t(`Maintenance`)}</span>*/}
                {/*<div className={cx(*/}
                    {/*"sidebar-item",*/}
                    {/*location.pathname.indexOf("/csp-maintenance-schedule") > -1 ? "active" : "",*/}
                {/*)}>*/}
                    {/*<Link to="/csp-maintenance-schedule">*/}
                        {/*<span className="sidebar-link-label">{t(`FW Updates`)}</span>*/}
                    {/*</Link>*/}
                {/*</div>*/}
            {/*</div>*/}
        </div>
    };

    return (
        <div id="sidebar">
            {
                localStorage.getItem("OMNI2_ROLE") === ROLES.OSP ?
                    <div className="sidebar-header sidebar-header-left">{t(`OSP`)}</div>
                    : null
            }
            {
                localStorage.getItem("OMNI2_ROLE") === ROLES.CSP ?
                    <div className="sidebar-header sidebar-header-left">{t(`CSP`)}</div>
                    : null
            }
            {
                localStorage.getItem("OMNI2_ROLE") === ROLES.OSP
                    ? renderOSPMenu()
                    : null
            }
            {
                localStorage.getItem("OMNI2_ROLE") === ROLES.CSP
                    ? renderCSPMenu()
                    : null
            }
            <div className="sidebar-footer">
                {t(`Version`)} {Versions.iresp_build}
            </div>
        </div>
    )
}