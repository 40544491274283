export const changeTheme = (theme) => {
    switch (theme) {
        case "red":
            document
                .documentElement
                .style
                .setProperty("--main-color", "#FE7069");
            break;
        case "blue":
            document
                .documentElement
                .style
                .setProperty("--main-color", "rgb(41, 171, 224)");
                // .setProperty("--main-color", "#43CABD");
            break;
        default:
            document
                .documentElement
                .style
                .setProperty("--main-color", "#43CABD");
    }
};