import {connect} from 'react-redux'
import {Diagnostics} from "./Diagnostics";
import {cspDashboard} from "../../../redux/actions/csp/cspDashboard";

const mapStateToProps = state => ({
    listOfCC: state.cspDashboardReducer.listOfCC || [],
    listOfWarranty: state.cspDashboardReducer.listOfWarranty || [],
    listOfDiagnostics: state.cspDashboardReducer.listOfDiagnostics || [],
    result: [
        {csp_id: "CSP001", csp_name: "CSP 1", cc_id: "CC0001", cc_name: "CC 1", beds: 100}
    ],
});

function mapDispatchToProps(dispatch) {
    return ({
        getListOfCC: () => dispatch(cspDashboard.getListOfCC()),
        listWarrantyByOahId: (oah) => dispatch(cspDashboard.listWarrantyByOahId(oah)),
        listDiagnostics: (oah) => dispatch(cspDashboard.listDiagnostics(oah)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Diagnostics);