import {dataProvider} from "../../connections/net";
import {appAction} from "./appAction";

export const SET_ERROR = "thresholdAction/action/SET_ERROR";
export const SET_STATE = "thresholdAction/action/SET_STATE";
export const GET_BEDS = "thresholdAction/action/GET_BEDS";
export const UPDATE_BED = "thresholdAction/action/UPDATE_BED";
export const CLEAR_DATA = "thresholdAction/action/CLEAR_DATA";

export const thresholdAction = {
    getThresholdSetting: () => async dispatch => {
        dispatch(appAction.openLoading());
        try {
            const {status_code, oah} = await dataProvider.getThresholdSetting();
            if (status_code === 0 && oah) {
                dispatch({type: GET_BEDS, payload: oah});
            }
        } catch (e) {
            console.log("Error: ", e.message);
            // dispatch(appAction.reconnect(true))
        }
        dispatch(appAction.closeLoading());
    },
    updateThresholdSetting: (updated) => async dispatch => {
        try {
            const {status_code, bed: beds} = await dataProvider.updateThresholdSetting(updated);
            if (status_code === 0) {
                const found = beds.find(b => b.id === updated.friendly_name);
                dispatch({type: UPDATE_BED, payload: {updated, found}});
                dispatch(thresholdAction.getThresholdSetting());
            }
        } catch (e) {
            console.log("Error: ", e.message);
        }
    },
    setError: (error) => dispatch => dispatch({type: SET_ERROR, payload: error}),
    setState: (state) => dispatch => dispatch({type: SET_STATE, payload: state}),
    clearData: () => dispatch => dispatch({type: CLEAR_DATA})
};