import {GET_AVAILABLE_BED, SET_ERROR} from "../actions/enrollAction";

const initialState = {};

const enrollReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AVAILABLE_BED:
            const {beds} = action.payload;
            return {...state, availableBeds: beds.filter(b => b.patient_id === null || b.patient_id === "")};
        case SET_ERROR:
            return {...state, error: action.payload};
        default:
            return state
    }
};

export default enrollReducer;