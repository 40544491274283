import React, {useEffect} from "react";
import moment from "moment";
// import "moment/locale/zh-cn";
import "moment/locale/en-sg";
import {useLocation, useHistory} from 'react-router-dom';
import {AppBar} from "./AppBar";
import {SideBar} from "./SideBar";
import "./Layout.css";
import {Loading} from "./Loading";
import {messageWrapper as t} from "../../utils/message-wrapper";
import {EspModal} from "../common/EspModal";
import LoadingImage from "../../images/gw_tablet_spinner.gif";
import AppIcon from "../../images/oac/favicon_io/favicon-32x32.png";
import "./modal.css";
import "./Report.css";
import {useIntl} from "react-intl";
import backIcon from "../../images/Report_Back.png";
import cx from "classnames";
import {ROLES} from "../../constants/roles";
import {LANG} from "../../constants/lang";

let intervaler, intervaler2, retry = 30;

export function Layout(props) {

    // const [state, setState] = useState({});
    const location = useLocation();
    const history = useHistory();
    const intl = useIntl();

    const cnCss = props.locale !== "en" ? "" : "";

    const token = localStorage.getItem("token");

    useEffect(() => {
        if (localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") !== "en") {
            document
                .documentElement
                .style
                .setProperty("--main-font", "Noto");
        } else {
            document
                .documentElement
                .style
                .setProperty("--main-font", "Roboto Condensed");
        }

        // localStorage.setItem("OMNI2_TILES_OPENING", "false");
        if (window.location.href.indexOf("/login") === -1) {
            if (token) {
                // polling
                intervaler = setInterval(() => {
                    props.pollingByBedStatus();
                }, 60000);
            }
        }

        intervaler2 = setInterval(() => {
            if (localStorage.getItem("OMNI2_DISCONNECTED") === "true") {
                if (retry-- === 30) {
                    props.pollingByBedStatus();
                } else if (retry-- > 0) {
                    props.closeLoading();
                    props.reconnect(true);
                } else {
                    props.setOverRetry(true);
                    props.reconnect(false);
                }
            } else {
                retry = 30;
                props.reconnect(false, true);
                props.setOverRetry(false);
            }
        }, 4000);

        // TODO: 09/03/2021 - Check it work or not on mobile
        try {
            const ch = new BroadcastChannel('already');
            ch.addEventListener('message', function (e) {
                if (e.data === "ping" && window.location.href.indexOf("login") === -1) {
                    ch.postMessage('pong');
                } else if (e.data === "pong") {
                    ch.close();
                    props.setAlreadyOpen();
                }
            });
            ch.postMessage('ping');
        } catch (e) {
            console.log(e.message);
        }

        function checkKeyPress(e) {
            let evtobj = window.event ? window.event : e;

            if (evtobj.keyCode === 37 && evtobj.ctrlKey && evtobj.altKey) {
                // arrow left - change language to english
                props.changeLanguage(LANG.en);
            }

            if (evtobj.keyCode === 39 && evtobj.ctrlKey && evtobj.altKey) {
                // arrow right - change language to installed language
                props.changeLanguage(LANG.ja);
            }
        }

        const el = document.getElementById('body');

        if (el) {
            el.addEventListener('keydown', checkKeyPress);
        }

        return () => {
            clearTimeout(intervaler);
            clearTimeout(intervaler2);
            el.removeEventListener('keydown', checkKeyPress);
        };
    }, []);

    if (props.locale !== "en") {
        moment.locale(props.locale);
    } else {
        moment.locale("en-sg");
    }

    const headers = {
        "": "Dashboard",
        "/": "Dashboard",

        "/osp-dashboard": "CSP Management",
        "/osp-maintenance-release": "SW/FW Release",

        "/csp-cc-management": "Care Centre Management",
        "/csp-dashboard": "Dashboard",
        "/csp-maintenance-schedule": "Firmware Updates",
    };

    const alreadyOpen = props.alreadyOpen
        ? <EspModal
            title={t(`System`)}
            noFooter={true}
            // definedButtons={[t(`Dismiss`)]}
            clazz="already-open-modal"
            content={t(`This page is already open on another tab.`)}
            // content2={t(`Pressing Dismiss will close this tab.`)}
            handlers={[() => {
                // var event = document.createEvent('Event');
                // event.initEvent('hello');
                // document.dispatchEvent(event);
            }]}
            isOpen={true}/>
        : null;

    const logoutModal = props.shouldLogout && token
        ? <EspModal
            title={t(`Error Encountered`)}
            definedButtons={[t(`OK`)]}
            clazz="logout-modal"
            content={t(`Your account has been logged in on another device, please login again.`)}
            handlers={[() => {
                props.handleLogout();
                // window.location.href = "/login";
            }]}
            isOpen={true}/>
        : null;

    const reconnectModal = <EspModal
        title={t(`Connection to cloud interrupted`)}
        definedButtons={[""]}
        clazz={cx(
            "logout-modal",
            props.isReconnect ? "" : "d-none"
        )}
        content={t(`Attempting to re-connect…`)}
        noFooter={true}
        loading={true}
        LoadingImage={LoadingImage}
        handlers={[() => {
            // props.handleLogout();
            // window.location.href = "/login";
            // props.history.push("/login");
        }]}
        isOpen={true}/>

    let overRetryModal = props.overRetry
        ? <EspModal
            title={t(`Connection to Cloud lost`)}
            definedButtons={[t(`OK`)]}
            clazz="retry-modal"
            content={t(`Contact Technical Support to resolve this before proceeding.`)}
            // noFooter={true}
            // loading={true}
            handlers={[() => {
                props.setHideOverRetry(true);
            }]}
            isOpen={true}/>
        : undefined;

    const changeFavicon = () => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
        }
        link.href = AppIcon;
    };

    if (
        window.location.pathname.indexOf("/login") > -1
        || window.location.pathname.indexOf("/preview") > -1
        || window.location.pathname.indexOf("/reset-password") > -1
    ) {
        // changeFavicon();
        return (
            <div className={"container-fluid" + cnCss}>
                <AppBar
                    key="app-bar"
                    auth={false}
                    languageOptions={props.languageOptions}
                    selectedLanguage={props.selectedLanguage}
                    changeLanguage={props.changeLanguage}
                />
                {props.children}
                <Loading loading={props.loading}/>
            </div>
        )
    }

    if (window.location.pathname.indexOf("/cc-admin-live-tile") > -1) {
        return (
            <div className={"container-fluid" + cnCss}>
                {props.children}
                {logoutModal}
                {reconnectModal}
                {overRetryModal}
                <Loading loading={props.loading}/>
            </div>
        )
    }

    // changeFavicon();

    if (token && !headers[location.pathname]) {
        window.location.href = "/osp-dashboard";
    }

    return (
        <div className={"container-fluid" + cnCss}>
            <AppBar
                key="app-bar"
                auth={true}
                audio={props.audio}
                liveViewIsOpen={props.liveViewIsOpen}
                onOffAudio={props.onOffAudio}
                languageOptions={props.languageOptions}
                selectedLanguage={props.selectedLanguage}
                changeLanguage={props.changeLanguage}
                handleLogout={props.handleLogout}
                history={history}
                isReconnect={props.isReconnect}
                deleteOldLiveTile={props.deleteOldLiveTile}
                setLiveTile={props.setLiveTile}
                liveTile={props.liveTile}
                hideOverRetry={props.hideOverRetry}
                overRetry={props.overRetry}
                setHideOverRetry={props.setHideOverRetry}
                mailUnread={props.mailUnread}
                getInboxMessage={props.getInboxMessage}
                versions={props.versions}
                // toggle={props.toggleSidebar}
            />
            <SideBar isOpen={true} isConfigurationSaved={props.isSaved}/>
            <div id="main">
                <div className="main-header">
                    {
                        window.location.pathname.indexOf("report") > -1
                            ? <div className="left-icons">
                                <img src={backIcon} onClick={() => history.go(-1)}/>
                            </div>
                            : undefined
                    }
                    {t(`${headers[location.pathname]}`)}
                    {/*{middleIcon}*/}
                    {/*{rightIcon}*/}
                </div>
                <div className="main-body">
                    {props.children}
                </div>
            </div>
            <Loading loading={props.loading}/>
            {logoutModal}
            {localStorage.getItem("OMNI2_ROLE") ? reconnectModal : null}
            {localStorage.getItem("OMNI2_ROLE") ? overRetryModal : null}
            {localStorage.getItem("OMNI2_ROLE") ? alreadyOpen : null}
        </div>
    );
}