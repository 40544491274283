export const PATIENT_STATUS = {
    Sleeping: 0,
    Movement:1,
    Absent: 2,
    Awake: 3,
    TimeToSleep: 4,
    Judgement: 5,
    EOL: 15,
    Inaccurate: 16,
    E1: 254,
    E8: 251,
    Initializing: 240,
};

export const PATIENT_STATUS_MAP = {
    0: "Sleeping",
    1: "Movement",
    2: "Off-Pillow", // Absent
    3: "Awake",
    4: "Awake", // TTS
    5: "Judgement",
    15: "Vital Signs not detected", // EOL
    16: "Inaccurate",
    254: "E1",
    251: "E8",
    240: "Initialization in progress",
};

export const BED_STATUS_MAP = {
    0: "Offline",
    251: "Sensor mat faulty",
    253: "Sensor mat faulty",
    252: "Updating",
};