import React, {useEffect, useState} from "react";
import {useAuth} from "../../../connections/net";
import {messageWrapper as t} from "../../../utils/message-wrapper";
import "./Diagnostics.css";
import {OACSearchField} from "../../common/oac/OACSearchField";
import {EspTable} from "../../common/EspTable";
import {OACSortIcon} from "../../common/oac/OACSortIcon";
import moment from "moment";
import {OACFilter} from "../../common/oac/OACFilter";
import {formatDisplayDate} from "../../../utils/format";
import {OACFilterCC} from "../../common/oac/OACFilterCC";
import {BED_STATUS_MAP, PATIENT_STATUS, PATIENT_STATUS_MAP} from "../../../utils/pos-status";
import {OACPagingSelection} from "../../common/oac/OACPagingSelection";
import {OACPaging} from "../../common/oac/OACPaging";
import {OACFilterSearch} from "../../common/oac/OACFilterSearch";
import {useIntl} from "react-intl";

const RSSI_MAP = {
    "-1": "Offline",
    0: "Offline",
    1: "Poor",
    2: "Acceptable",
    3: "Good",
    4: "Best",
    "n/a": t("n/a"),
};


export function Diagnostics(props) {

    useAuth();

    const intl = useIntl();

    const translatedText = {
        Offline: intl.formatMessage({ id: "Offline" }),
        Service_Expired: intl.formatMessage({ id: "Service expired" }),
        Update_Available: intl.formatMessage({ id: "Update available" }),
        Service_Active: intl.formatMessage({ id: "Service active" }),
        Warranty_Expired: intl.formatMessage({ id: "Warranty expired" }),
        Warranty_Available: intl.formatMessage({ id: "Warranty available" }),
        Warranty_Active: intl.formatMessage({ id: "Warranty active" }),
    };

    const [state, setState] = useState({tab: 1, page: 1, itemsPerPage: 10});

    useEffect(() => {
        props.getListOfCC();
    }, []);

    useEffect(() => {
        if (props.listOfCC && state.tab === 1) {
            const filteredCC = props.listOfCC
                .filter(cc => {
                    return !state.selectedCC || !state.selectedCC?.value || cc.oah_id === state.selectedCC?.value;
                })
                .filter(b => {
                    return !state.searchText || b.display_name?.toUpperCase().indexOf(state.searchText?.toUpperCase()) > -1;
                })
                .sort((a, b) => {
                    let dataOrder = 0;
                    if (!a[state.sortField]) dataOrder = 1;
                    else if (!b[state.sortField]) dataOrder = -1;
                    else dataOrder = a[state.sortField] <= b[state.sortField] ? 1 : -1;
                    return dataOrder * state.sortOrder;
                });
            const itemsCCThisPage = [...filteredCC].splice((state.page - 1) * state.itemsPerPage, state.itemsPerPage);
            setState({...state, filteredCC: filteredCC, itemsCCThisPage});
        }
    }, [props.listOfCC, state.selectedCC, state.page, state.itemsPerPage, state.sortField, state.sortOrder, state.searchText]);

    // useEffect(() => {
    //     if (props.listOfWarranty && props.listOfWarranty.length > 0 && state.tab === 2) {
    //         const filteredInstallation = [...props.listOfWarranty]
    //             .filter(b => {
    //                 return true;
    //             })
    //             .sort((a, b) => {
    //                 let dataOrder = 0;
    //                 if(!a[state.sortField]) dataOrder = 1;
    //                 else if(!b[state.sortField]) dataOrder = -1;
    //                 else dataOrder = a[state.sortField] <= b[state.sortField] ? 1 : -1;
    //                 return dataOrder * state.sortOrder;
    //             });
    //
    //         const itemsInstallationThisPage = [...filteredInstallation].splice((state.page - 1) * state.itemsPerPage, state.itemsPerPage);
    //         setState({...state, filteredInstallation: filteredInstallation, itemsInstallationThisPage});
    //     }
    // }, [props.listOfWarranty,  state.page, state.itemsPerPage, state.sortField, state.sortOrder]);

    useEffect(() => {
        if (props.listOfDiagnostics && state.tab === 3) {
            const filteredDiagnostics = props.listOfDiagnostics
                .filter(b => {
                    if (!state.filterValue || state.filterValue === "ALL") return true;

                    if (state.filterField && state.filterField.indexOf("time") > -1) {
                        return moment(b[state.filterField]).format("YYYYMMDD") === moment(state.filterValue).format("YYYYMMDD");
                    }

                    if (state.filterField && state.filterField.indexOf("status") > -1) {
                        return b[state.filterField]?.indexOf(state.filterValue) > -1;
                    }

                    return b[state.filterField] === state.filterValue;
                });
            const itemsDiagnosticsThisPage = [...filteredDiagnostics].splice((state.page - 1) * state.itemsPerPage, state.itemsPerPage);
            setState({...state, filteredDiagnostics: filteredDiagnostics, itemsDiagnosticsThisPage});
        }
    }, [props.listOfDiagnostics, state.page, state.itemsPerPage, state.sortField, state.sortOrder]);

    useEffect(() => {
        if (props.listOfWarranty && state.tab === 2) {
            const filteredWarranty = [...props.listOfWarranty]
                .filter(b => {
                    return true;
                })
                .sort((a, b) => {
                    let dataOrder = 0;
                    if (!a[state.sortField]) dataOrder = 1;
                    else if (!b[state.sortField]) dataOrder = -1;
                    else dataOrder = a[state.sortField] <= b[state.sortField] ? 1 : -1;
                    return dataOrder * state.sortOrder;
                });
            const itemsWarrantyThisPage = [...filteredWarranty].splice((state.page - 1) * state.itemsPerPage, state.itemsPerPage);
            setState({...state, filteredWarranty: filteredWarranty, itemsWarrantyThisPage});
        }
    }, [props.listOfWarranty, state.page, state.itemsPerPage, state.sortField, state.sortOrder]);

    const generateOverviewTable = () => {
        const generateHeader = () => {
            return <tr>
                <td>
                    {t(`CC ID`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "oah_id")}/>
                </td>
                <td>
                    {t(`Care Centre`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "display_name")}/>
                </td>
                <td>
                    {t(`Installed Beds`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "beds")}/>
                </td>
            </tr>
        };
        const generateBody = () => {
            if (!state.itemsCCThisPage || state.itemsCCThisPage.length < 1) {
                return <tr>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                return state.itemsCCThisPage
                // .sort((a, b) => compareOACBedName(a.company_name, b.company_name))
                    .map(b => {
                        return <tr>
                            <td>{b.oah_id}</td>
                            <td>{b.display_name}</td>
                            <td>{b.beds}</td>
                        </tr>
                    });
            }
        };

        let total = 0;
        state.itemsCCThisPage.forEach(cc => {
            total = total + cc.beds;
        });

        return <>
            <div className="table-descr">
                <div className="inline w780">
                    {t(`Display`)}
                    &nbsp;<OACPagingSelection isSearchable={true} handleChangePage={handleChangePage}/>&nbsp;
                    {t(`records per page`)}
                </div>
                <div className="inline w19p">
                </div>
                <div className="inline"></div>
                <div className="inline w50p right">
                    <span className="link">
                        <OACSearchField inputClazz="w325px"
                                        onChange={(e) => setState({...state, search: e.target?.value})}/>
                    </span>
                </div>
            </div>
            <div className="mt5">
                {t(`Total Beds displayed:`)}&nbsp;<b>{total}</b>
            </div>
            <EspTable className="overview" renderHeader={generateHeader}
                      renderBody={generateBody}/>
            <OACPaging
                clazz="overview-paging"
                total={state.filteredCC?.length}
                itemsPerPage={state.itemsPerPage}
                page={state.page}
                setPage={(page) => setState({...state, page: page})}
            />
        </>
    };

    const generateInstallationTable = () => {
        const generateListHeader = () => {
            return <>
                <tr>
                    <td className="w1" rowSpan={2}>
                        {t(`Beds`)}
                    </td>
                    <td className="w3" colSpan={2}>
                        {t(`Cloud Status`)}
                    </td>
                    <td className="w3" colSpan={2}>
                        {t(`BCU & Sensor Warranty`)}
                    </td>
                </tr>
                <tr>
                    <td className="w2">
                        {t(`Start Date`)}
                        <OACSortIcon handleSort={(order) => handleSort(order, "cloud_start")}/>
                    </td>
                    <td className="w2">{t(`End Date`)}</td>
                    <td className="w2">{t(`Start Date`)}</td>
                    <td className="w2">{t(`End Date`)}</td>
                </tr>
            </>
        };

        const generateListBody = () => {
            if (!state.itemsInstallationThisPage || state.itemsInstallationThisPage.length < 1) {
                return <tr>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                return state.itemsInstallationThisPage
                // .sort((a, b) => compareOACBedName(a.company_name, b.company_name))
                    .map(b => {
                        return <tr>
                            <td className="w1">
                                <span className="link" onClick={() => setState({
                                    ...state,
                                    historyBed: b
                                })}>{b.bed_name}
                            </span></td>
                            <td className="w2">{b.cloud_start ? moment(b.cloud_start).format("YYYY-MM-DD") : "--"}</td>
                            <td className="w2">{b.cloud_end ? moment(b.cloud_end).format("YYYY-MM-DD") : "--"}</td>
                            <td className="w2">{b.warranty_start ? moment(b.warranty_start).format("YYYY-MM-DD") : "--"}</td>
                            <td className="w2">{b.warranty_end ? moment(b.warranty_end).format("YYYY-MM-DD") : "--"}</td>
                        </tr>
                    });
            }
        };

        const generateChangeHeader = () => {
            return <>
                <tr>
                    <td>
                        {t(`Component`)}
                        <OACSortIcon handleSort={(order) => handleSort(order, "component")}/>
                    </td>
                    <td>
                        {t(`Serial Number`)}
                    </td>
                    <td>
                        {t(`Date Commissioned`)}
                    </td>
                </tr>
            </>
        };

        const generateChangeBody = () => {
            if (!state.historyBed.warranty_history || state.historyBed.warranty_history.length < 1) {
                return <tr>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                return state.historyBed.warranty_history
                // .sort((a, b) => compareOACBedName(a.company_name, b.company_name))
                    .map(b => {
                        return <tr>
                            <td>{b.component}</td>
                            <td>{b.version}</td>
                            <td>{moment(b.upload_date).format("YYYY-MM-DD")}</td>
                        </tr>
                    });
            }
        };

        return <>
            <div className="table-descr">
                <div className="inline w780">
                    {t(`Display`)}
                    &nbsp;<OACPagingSelection handleChangePage={handleChangePage}/>&nbsp;
                    {t(`records per page`)}
                </div>
                <div className="inline w19p">
                </div>
                <div className="inline"></div>
                <div className="inline w50p right">
                    <span className="link">
                        <OACSearchField inputClazz="w325px"
                                        onChange={(e) => setState({...state, search: e.target?.value})}/>
                    </span>
                </div>
            </div>
            <div>
                <div className="inline">
                    <EspTable className="installation-list" renderHeader={generateListHeader}
                              renderBody={generateListBody}/>
                    <OACPaging
                        clazz="installation-paging"
                        total={state.filteredInstallation?.length}
                        itemsPerPage={state.itemsPerPage}
                        page={state.page}
                        setPage={(page) => setState({...state, page: page})}
                    />
                </div>
                {
                    state.historyBed
                        ? <div className="inline ml90 v-top">
                            <div className="table-descr">
                                <b>{t(`History of changes:`)}</b>
                                <b className="ml90">{t(`Bed:`)}</b>
                                &nbsp;&nbsp;<span className="color-red font-weight-bold">{state.historyBed?.bed_name}</span>
                            </div>
                            <EspTable className="installation-change" renderHeader={generateChangeHeader}
                                      renderBody={generateChangeBody}/>
                        </div>
                        : undefined
                }
            </div>
        </>
    };

    const generateDiagnosticsTable = () => {
        const generateHeader = () => {
            return <tr>
                <td>
                    {t(`Bed`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "bed_name")}/>
                </td>
                <td>
                    {t(`Bed Status`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "bed_status")}/>
                </td>
                <td>
                    {t(`Signal strength`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "rssi")}/>
                </td>
                <td>
                    {t(`Access Point`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "ssid")}/>
                </td>
                <td>
                    {t(`Resident Status`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "patient_status")}/>
                </td>
                <td>
                    {t(`RESP`)}
                    <br/>
                    {t(`(RPM)`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "rpm")}/>
                </td>
                <td>
                    {t(`PR`)}
                    <br/>
                    {t(`(BPM)`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "bpm")}/>
                </td>
            </tr>
        };
        const generateBody = () => {
            if (!state.itemsDiagnosticsThisPage || state.itemsDiagnosticsThisPage.length < 1) {
                return <tr>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                return state.itemsDiagnosticsThisPage
                    .map(b => {
                        const {bed_status, patient_status, rpm, bpm} = getStatus(b.sensor_data, b.rssi, b.fw_smcu, b.fw_cdmcu);
                        return {
                            ...b,
                            bed_status,
                            patient_status,
                            rpm,
                            bpm
                        };
                    })
                    .sort((a, b) => {
                        let dataOrder = 0;
                        if (!a[state.sortField]) dataOrder = 1;
                        else if (!b[state.sortField]) dataOrder = -1;
                        else dataOrder = a[state.sortField] <= b[state.sortField] ? 1 : -1;
                        return dataOrder * state.sortOrder;
                    })
                    .map(b => {
                        const {bed_status, patient_status, rpm, bpm} = b;
                        const rssi = getRssi(b.rssi, b.sensor_data, b.ssid);
                        return <tr>
                            <td className={b.ssid && ((bed_status !== "Normal" && bed_status !== "n/a") || rssi === "Offline") ? "bg-error" : ""}>{b.bed_name}</td>
                            <td className={(bed_status !== "Normal" && bed_status !== "n/a") ? "bg-error" : ""}>{t(bed_status)}</td>
                            <td className={b.ssid && rssi === "Offline" ? "bg-error" : ""}>{b.ssid ? t(rssi) : t("n/a")}</td>
                            <td>{b.ssid ? b.ssid : t("n/a")}</td>
                            <td>{patient_status && rssi !== "Offline" ? t(patient_status): t("n/a")}</td>
                            <td>{rssi !== "Offline" ? rpm : t("n/a")}</td>
                            <td>{rssi !== "Offline" ? bpm : t("n/a")}</td>
                        </tr>
                    });
            }
        };

        const getStatus = (data, rssi, fw_smcu, fw_cdmcu) => {
            if (!data) return {
                bed_status: "n/a",
                patient_status: "n/a",
                rpm: t("n/a"),
                bpm: t("n/a")
            };

            const patient_status = PATIENT_STATUS_MAP[data.status];

            let bed_status = data.status === PATIENT_STATUS.E1 || data.status === PATIENT_STATUS.E8 ? "Sensor mat faulty" : undefined;

            if (fw_smcu === 3 || fw_cdmcu === 3) {
                bed_status = "Updating";
            }

            return {
                bed_status: !bed_status ? "Normal" : bed_status,
                patient_status: patient_status,
                rpm: data.br > 200 ? t("n/a") : data.br,
                bpm: data.hr > 200 ? t("n/a") : data.hr
            }
        };

        const now = moment().utcOffset(state.timezone).valueOf();
        const getRssi = (rssi, status = {},) => {
            const lastUpdate = status?.last_update;
            if (!lastUpdate || now - lastUpdate > 35000) {
                return "Offline";
            }

            if (!rssi) return t("n/a");

            return RSSI_MAP[rssi];
        };

        return <>
            <div className="table-descr">
                <div className="inline w780">
                    {t(`Display`)}
                    &nbsp;<OACPagingSelection handleChangePage={handleChangePage}/>&nbsp;
                    {t(`records per page`)}
                </div>
                <div className="inline w19p">
                </div>
                <div className="inline"></div>
                <div className="inline w50p right">
                    <span className="link">
                        <OACSearchField inputClazz="w325px"
                                        onChange={(e) => setState({...state, search: e.target?.value})}/>
                    </span>
                </div>
            </div>
            <EspTable className="result" renderHeader={generateHeader}
                      renderBody={generateBody}/>
            <OACPaging
                clazz="result-paging"
                total={state.filteredDiagnostics?.length}
                itemsPerPage={state.itemsPerPage}
                page={state.page}
                setPage={(page) => setState({...state, page: page})}
            />
        </>
    };

    const generateWarrantyTable = () => {
        const generateHeader = () => {
            return <>
                <tr>
                    <td rowSpan={2} className="w1">
                        {t(`Bed`)}
                        <OACSortIcon handleSort={(order) => handleSort(order, "bed_name")}/>
                    </td>
                    <td colSpan={4} className="w4">
                        {t(`OAC Cloud`)}
                    </td>
                    <td colSpan={4} className="w5">
                        {t(`Hardware`)}
                    </td>
                </tr>
                <tr>
                    <td className="w12">
                        {t(`Service Type`)}
                        <OACSortIcon handleSort={(order) => handleSort(order, "cloud_premium")}/>
                    </td>
                    <td className="w2">
                        {t(`Service Start Date`)}
                        <OACSortIcon handleSort={(order) => handleSort(order, "cloud_start")}/>
                    </td>
                    <td className="w22">
                        {t(`Service Expiry Date`)}
                        <OACSortIcon handleSort={(order) => handleSort(order, "cloud_end")}/>
                    </td>
                    <td className="w3">
                        {t(`Status`)}
                        <OACSortIcon handleSort={(order) => handleSort(order, "cloud_end")}/>
                    </td>
                    <td className="w6">
                        {t(`BCU Model`)}
                        <OACSortIcon handleSort={(order) => handleSort(order, "bcu_model")}/>
                    </td>
                    <td className="w2">
                        {t(`Warranty Start Date`)}
                        <OACSortIcon handleSort={(order) => handleSort(order, "warranty_start")}/>
                    </td>
                    <td className="w22">
                        {t(`Warranty Expiry Date`)}
                        <OACSortIcon handleSort={(order) => handleSort(order, "warranty_end")}/>
                    </td>
                    <td className="w3">
                        {t(`Status`)}
                        <OACSortIcon handleSort={(order) => handleSort(order, "warranty_end")}/>
                    </td>
                </tr>
            </>
        };
        const generateBody = () => {
            if (!state.itemsWarrantyThisPage || state.itemsWarrantyThisPage.length < 1) {
                return <tr>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                return state.itemsWarrantyThisPage
                // .sort((a, b) => compareOACBedName(a.company_name, b.company_name))
                    .map(b => {
                        const now = moment();

                        let cloudStatus;
                        if (!b.cloud_end) cloudStatus = t("n/a");
                        else if (moment(b.cloud_end).isBefore(now)) cloudStatus = translatedText.Service_Expired;
                        else if (moment(b.cloud_end).isBefore(now.add(-2, "months"))) cloudStatus = translatedText.Update_Available;
                        else if (moment(b.cloud_end).isAfter(now.add(-2, "months"))) cloudStatus = translatedText.Service_Active;

                        let warrantyStatus;
                        if (!b.warranty_end) warrantyStatus = t("n/a");
                        else if (moment(b.warranty_end).isBefore(now)) warrantyStatus = translatedText.Warranty_Expired;
                        else if (moment(b.warranty_end).isBefore(now.add(-2, "months"))) warrantyStatus = translatedText.Warranty_Available;
                        else if (moment(b.warranty_end).isAfter(now.add(-2, "months"))) warrantyStatus = translatedText.Warranty_Active;

                        return <tr>
                            <td className="w1">
                                <span className="link" onClick={() => setState({
                                    ...state,
                                    historyBed: b
                                })}>
                                    {b.bed_name}
                                </span>
                            </td>
                            <td className="w12">{b.cloud_premium ? t("Premium") : t("Cloud-Lite")}</td>
                            <td className="w2">{t(formatDisplayDate(b.cloud_start))}</td>
                            <td className="w22">{t(formatDisplayDate(b.cloud_end))}</td>
                            <td className={cloudStatus === translatedText.Service_Expired ? "color-red w3" : "w3"}>{cloudStatus}</td>
                            <td className="w6">{b.model || t("n/a")}</td>
                            <td className="w2">{t(formatDisplayDate(b.warranty_start))}</td>
                            <td className="w22">{t(formatDisplayDate(b.warranty_end))}</td>
                            <td className={warrantyStatus === translatedText.Warranty_Expired ? "color-red w3" : "w3"}>{warrantyStatus}</td>
                        </tr>
                    });
            }
        };

        const generateChangeHeader = () => {
            return <>
                <tr>
                    <td>
                        {t(`Component`)}
                        <OACSortIcon handleSort={(order) => handleSortComponent(order, "component")}/>
                    </td>
                    <td>
                        {t(`Model`)}
                        <OACSortIcon handleSort={(order) => handleSortComponent(order, "bcu_model")}/>
                    </td>
                    <td>
                        {t(`Serial Number`)}
                        <OACSortIcon handleSort={(order) => handleSortComponent(order, "serial")}/>
                    </td>
                    <td>
                        {t(`Date Commissioned`)}
                        <OACSortIcon handleSort={(order) => handleSortComponent(order, "comissioned")}/>
                    </td>
                </tr>
            </>
        };

        const generateChangeBody = () => {
            if (!state.historyBed.warranty_history || state.historyBed.warranty_history.length < 1) {
                return <tr>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                return state.historyBed.warranty_history
                    .sort((a, b) => state.sortComponentOrder * (a[state.sortComponentField] > b[state.sortComponentField] ? 1 : -1))
                    .map(b => {
                        return <tr>
                            <td>{b.component}</td>
                            <td>{t("n/a")}</td>
                            <td>{b.serial}</td>
                            <td>{moment(b.comissioned).format("YYYY-MM-DD")}</td>
                        </tr>
                    });
            }
        };

        const handleSortComponent = (order, field) => {
            setState({...state, sortComponentField: field, sortComponentOrder: order})
        };

        return <>
            <div className="table-descr">
                <div className="inline w780">
                    {t(`Display`)}
                    &nbsp;<OACPagingSelection handleChangePage={handleChangePage}/>&nbsp;
                    {t(`records per page`)}
                </div>
                <div className="inline w19p">
                </div>
                <div className="inline"></div>
                <div className="inline w50p right">
                    <span className="link">
                        <OACSearchField inputClazz="w325px"
                                        onChange={(e) => setState({...state, search: e.target?.value})}/>
                    </span>
                </div>
            </div>
            <div className="inline">
                <EspTable className="warranty" renderHeader={generateHeader}
                          renderBody={generateBody}/>
                <OACPaging
                    clazz="warranty-paging"
                    total={state.filteredWarranty?.length}
                    itemsPerPage={state.itemsPerPage}
                    page={state.page}
                    setPage={(page) => setState({...state, page: page})}
                />
            </div>
            {
                state.historyBed
                    ? <div className="inline v-top">
                        <div className="table-descr">
                            <div className="inline">
                                <b>{t(`History of changes:`)}</b>
                            </div>
                            <div className="inline right">
                                <b className="">{t(`Bed:`)}</b>
                                &nbsp;&nbsp;<span className="color-red font-weight-bold">{state.historyBed?.bed_name}</span>
                            </div>
                        </div>
                        <EspTable className="installation-change" renderHeader={generateChangeHeader}
                                  renderBody={generateChangeBody}/>
                    </div>
                    : undefined
            }
        </>
    };

    const handleSearch = (selectedCC) => {

        if (state.tab === 2 || state.tab === 4) {
            props.listWarrantyByOahId({oah_id: selectedCC.value});
        } else if (state.tab === 3) {
            props.listDiagnostics({oah_id: selectedCC.value});
        }
        const cc = props.listOfCC.find(cc => cc.oah_id === selectedCC.value);

        setState({...state, isSearch: true, selectedCC, timezone: cc.timezone});
    };

    const handleFilterSearch = (selected) => {
        if (selected.value || selected.label) {
            setState({...state, isSearch: true, selectedCC: selected, searchText: undefined});
        } else {
            setState({...state, isSearch: true, searchText: selected, selectedCC: undefined});
        }
    };

    const handleChangeTab = (num) => {
        setState({
            ...state,
            random: Math.random(),
            tab: num,
            filterField: undefined,
            isSearch: false,
            itemsThisPage: undefined
        });
    };

    const handleChangePage = (itemsPerPage) => {
        setState({...state, itemsPerPage: itemsPerPage})
    };

    const handleSort = (order, field) => {
        setState({...state, sortOrder: order, sortField: field});
    };

    return (
        <div className="diagnostics">
            <div className="data">
                <div className="tabs">
                    <a onClick={() => handleChangeTab(1)}
                       className={state.tab === 1 ? "selected" : ""}>{t(`CC Overview`)}</a>
                    <a onClick={() => handleChangeTab(2)}
                       className={state.tab === 2 ? "selected" : ""}>{t(`Installations`)}</a>
                    <a onClick={() => handleChangeTab(3)}
                       className={state.tab === 3 ? "selected" : ""}>{t(`Diagnostics`)}</a>
                    {/*<a onClick={() => handleChangeTab(4)}*/}
                    {/*className={state.tab === 4 ? "selected" : ""}>{t(`Warranty`)}</a>*/}
                </div>
                <div className="devider"></div>
            </div>
            {
                state.tab === 1
                    ? <OACFilterSearch handleSearch={handleFilterSearch} listOfCC={props.listOfCC} key={state.random}/>
                    : undefined
            }
            {
                state.tab === 2
                    ? <div className="">
                        <OACFilter key={state.random} hasAllOption={state.tab !== 2} listOfCC={props.listOfCC}
                                   handleSearch={handleSearch}/>
                    </div>
                    : undefined
            }
            {
                state.tab === 4 || state.tab === 3
                    ? <div className="">
                        <OACFilterCC key={state.random} hasAllOption={false} listOfCC={props.listOfCC}
                                     handleSearch={handleSearch}/>
                    </div>
                    :
                    undefined
            }
            {
                state.isSearch && state.tab === 1
                    ? generateOverviewTable()
                    : undefined
            }
            {
                state.isSearch && state.tab === 2
                    ? generateWarrantyTable()
                    : undefined
            }
            {
                state.isSearch && state.tab === 3
                    ? generateDiagnosticsTable()
                    : undefined
            }
            {/*{*/}
            {/*state.isSearch && state.tab === 4*/}
            {/*? generateWarrantyTable()*/}
            {/*: undefined*/}
            {/*}*/}
        </div>
    );
}