import {GET_ENCRYPTION_LIST, GET_WIFI} from "../../actions/osp-mgr/setupWifiAction";

const initialState = {};

const setupWifiReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ENCRYPTION_LIST:
            return {...state, methods: action.payload};
        case GET_WIFI:
            return {...state, addedNetworks: action.payload};
        default:
            return state
    }
};

export default setupWifiReducer;