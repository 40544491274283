import React, {useState, useRef} from "react";
import {useHistory} from 'react-router-dom';
import audioURL from "../../audioes/sound_1_mod_1d1_1s.mp3";
import audioURL2 from "../../audioes/Alarm6-1C1-single-R2.mp3";
import audioDisconnectUrl from "../../audioes/alarm1.mp3";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import UserImage from "../../images/user.png";
import {FontAwesomeIcon as FA} from "@fortawesome/react-fontawesome";
import {
    faInfoCircle,
    faKey,
    faSignInAlt,
    faSignOutAlt,
    faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import {messageWrapper as t} from "../../utils/message-wrapper";
import {EspModal} from "../common/EspModal";
import {IrespAudio} from "../common/IrespAudio";
import {ROLES} from "../../constants/roles";
import {useUserButtons} from "../../utils/hooks";
import volumOff from "../../images/volume-off.png";
import volumOn from "../../images/volume-on.png";
import {About} from "./About";

export function UserDropdown(props) {
    const [state, setState] = useState({
        isOpen: false,
        isTesting: false,
        isOpenAbout: false,
    });

    const history = useHistory();
    const btnRef = useRef(null);

    useUserButtons(btnRef, () => setState({...state, isOpen: false}));

    const handleLogin = () => {
        window.location.href = "/login";
    };

    const handleLogout = () => {
        props.handleLogout();
    };

    const handleShowAbout = () => {
        setState({...state, isOpenAbout: true, isOpen: false});
    };

    const handleShowAboutClose = () => {
        setState({...state, isOpenAbout: false});
    };

    const handlePlay = () => {
        const audio = document.getElementById("myAudio");
        if (!audio) return;
        if (audio.paused || audio.stopped) {
            audio.play();
            audio.loop = true;
        }
    };

    const handleStop = () => {
        const audio = document.getElementById("myAudio");
        if (!audio) return;
        if (!audio.paused) {
            audio.pause();
            audio.currentTime = 0;
        }
    };

    const handleCloseSoundTest = () => {
        handleStop();
        setState({...state, isOpenSoundTestModal: false, isTesting: false});
    };

    const handleOpenSoundTest = () => {
        handlePlay();
        setState({...state, isOpen: !state.isOpen, isOpenSoundTestModal: true, isTesting: true});
    };

    const handleOnOffAudio = () => {
        props.onOffAudio();
        setState({...state, isOpen: !state.isOpen});
    };

    const handleResetPassword = () => {
        setState({...state, isOpen: !state.isOpen});
        history.push("/osp-mgr-reset-password");
    };

    const renderDropdownMenu = () => {
        return <>
            <DropdownItem onClick={() => {
            }} tag="span">
                <FA icon={faUserCircle}/>
                <span className="text">{localStorage.getItem("OMNI2_USER")}</span>
            </DropdownItem>
            {
                (window.location.pathname.indexOf("/cc-admin-live-tile") > -1) && (
                    localStorage.getItem("OMNI2_ROLE") === ROLES.NurseAdmin
                )
                    ? <>
                        <DropdownItem onClick={handleOnOffAudio} tag="span">
                            {
                                props.audio
                                    ? <>
                                        {/*<FA icon={faVolumeUp}/>*/}
                                        <img src={volumOn}/>
                                        <span className="text pl0">{t(`Audio Alert is Unmuted`)}</span>
                                    </>
                                    : <>
                                        {/*<FA icon={faVolumeMute}/>*/}
                                        <img src={volumOff}/>
                                        <span className="text pl5">{t(`Audio Alert is Muted`)}</span>
                                    </>
                            }
                        </DropdownItem>
                        <DropdownItem disabled={!props.audio} onClick={handleOpenSoundTest} tag="span">
                            <FA icon={faSignOutAlt} style={{visibility: "hidden"}}/>
                            <span className="text">{t(`Test Audio Alert Volume`)}</span>
                        </DropdownItem>
                    </>
                    : null
            }
            {
                (localStorage.getItem("OMNI2_ROLE") === ROLES.OspMgr
                || localStorage.getItem("OMNI2_ROLE") === ROLES.NurseAdmin
                    // || localStorage.getItem("OMNI2_ROLE") === ROLES.NurseAdmin
                    // || localStorage.getItem("OMNI2_ROLE") === ROLES.Nurse)
                    && (window.location.pathname.indexOf("/cc-admin-live-tile") === -1))
                    ? <DropdownItem onClick={handleResetPassword} tag="span">
                        <FA icon={faKey}/>
                        <span className="text">{t(`Reset Password`)}</span>
                    </DropdownItem>
                    : undefined
            }
            <DropdownItem onClick={handleShowAbout} tag="span">
                <FA icon={faInfoCircle}/>
                <span className="text">{t(`About`)}</span>
            </DropdownItem>
            <DropdownItem onClick={handleLogout} tag="span">
                <FA icon={faSignOutAlt}/>
                <span className="text">{t(`Sign out`)}</span>
            </DropdownItem>
        </>
    };

    const isDisconnected = localStorage.getItem("OMNI2_DISCONNECTED") === "true"
        && (
            localStorage.getItem("OMNI2_TILES_OPENING") === "false"
            || window.location.pathname.indexOf("live-tile") > -1
        );

    return (
        <div className="inline" ref={btnRef}>
            <Dropdown
                toggle={() => {
                }}
                isOpen={state.isOpen}
                direction="down">
                <DropdownToggle
                    className="toggle-user"
                    tag="span">
                    <img onClick={() => setState({...state, isOpen: true})} id="user-btn" className="user"
                         src={UserImage}
                         width="20px"/>
                </DropdownToggle>
                <DropdownMenu>
                    {
                        props.auth
                            ? renderDropdownMenu()
                            : <DropdownItem onClick={handleLogin} tag="span">
                                <FA icon={faSignInAlt}/>
                                <span className="text">{t(`login`)}</span>
                            </DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
            <EspModal
                title={t(`Test Audio Alert Volume`)}
                content={t(`Adjust audio on your device to desired volume.`)}
                definedButtons={[t(`Stop`)]}
                handlers={[handleCloseSoundTest]}
                handleClose={handleCloseSoundTest}
                isOpen={state.isOpenSoundTestModal}/>
            {
                props.audio && !isDisconnected
                    ? <IrespAudio/>
                    : null
            }
            {
                props.audio && !isDisconnected && !state.isTesting
                    ? <audio id="alertAudio" loop>
                        <source src={audioURL} type="audio/mpeg"/>
                        Your browser does not support the audio element.
                    </audio>
                    : null
            }
            {
                props.audio && !isDisconnected && !state.isTesting
                    ? <audio id="alertAudio-2" loop>
                        <source src={audioURL2} type="audio/mpeg"/>
                        Your browser does not support the audio element.
                    </audio>
                    : null
            }
            <audio id="disconnectAudio" loop>
                <source src={audioDisconnectUrl} type="audio/mpeg"/>
                Your browser does not support the audio element.
            </audio>
            {
                state.isOpenAbout
                    ? <About handleShowAboutClose={handleShowAboutClose} versions={props.versions}/>
                    : undefined
            }
        </div>
    );
}