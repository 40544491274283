import {dataProvider} from "../../connections/net";
import {appAction} from "./appAction";

export const SET_ERROR = "dashboard/action/SET_ERROR";
export const SET_STATE = "dashboard/action/SET_STATE";
export const GET_DATA = "dashboard/action/GET_DATA";
export const GET_STATUS_DATA = "dashboard/action/GET_STATUS_DATA";
export const CLEAR_DATA = "dashboard/action/CLEAR_DATA";

export const dashboardAction = {
    getBedAllocation: () => async dispatch => {
        dispatch(appAction.openLoading());
        try {
            const {status_code, oah} = await dataProvider.getBedAllocation();
            if (status_code === 0 && oah) {
                // set time zone
                const {info} = oah;
                localStorage.setItem("OAH_TIMEZONE", info.timezone);

                // dispatch data
                dispatch({type: GET_DATA, payload: oah});
            }
        } catch(e) {
            console.log("Error:", e.message);
        }
        dispatch(appAction.closeLoading());
    },
    getBedAssignment: () => async dispatch => {
        try {
            const {status_code, beds} = await dataProvider.getBedAssignment();
            if (status_code === 0 && beds) {
                dispatch({type: GET_STATUS_DATA, payload: beds});
                dispatch(appAction.reconnect(false, true));
            } else {
                dispatch(appAction.reconnect(true));
            }
        } catch(e) {
            console.log("Error: ", e.message);
            if (e.message === "Disconnect") {
                dispatch(appAction.reconnect(true));
            } else if(e.message === "Unauthorize") {
                dispatch(appAction.showLogoutModal());
            }
        }
    },
    pollingByBedStatus: () => async dispatch => {
        try {
            const {status_code} = await dataProvider.authCheck();
            if (status_code === 0) {
                // dispatch({type: GET_STATUS_DATA, payload: beds});
                dispatch(appAction.reconnect(false, true));
            } else {
                dispatch(appAction.reconnect(true));
            }
        } catch(e) {
            console.log("Error: ", e.message);
            if (e.message === "Disconnect") {
                dispatch(appAction.reconnect(true));
            } else if(e.message === "Unauthorize") {
                dispatch(appAction.showLogoutModal());
            }
        }
    },
    setError: (error) => dispatch => dispatch({type: SET_ERROR, payload: error}),
    setState: (state) => dispatch => dispatch({type: SET_STATE, payload: state}),
    clearData: () => dispatch => dispatch({type: CLEAR_DATA})
};