import {GET_DATA, SET_PATIENT} from "../actions/reportAction";
import moment from "moment";

const initialState = {};

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DATA:
            const {sleeplogs, oah, patient_id} = action.payload;
            const {sl, vitalSign} = procesSleeplog(sleeplogs);
            // console.log(sl);
            return {...state, sleeplogs: sleeplogs, patient: extractPatient(oah, patient_id), sl, vitalSign};
        case SET_PATIENT:
            const {oah: oah2, patient_id: patient_id_2} = action.payload;
            return {...state, patient: extractPatient(oah2, patient_id_2)};
        default:
            return state
    }
};

function extractPatient(oah, patient_id) {
    const {patients, beds, info} = oah;
    const patientFound = patients.find(p => p.id === patient_id);
    const bedFound = beds.find(b => b.patient_id === patient_id);

    return {...patientFound, bed_name: bedFound?.bed_name, oah_name: info.oah_name};
}

function procesSleeplog(sleeplogs) {
    // console.log("sleeplogs", sleeplogs)
    // flat events
    const result = [], vitalSign = [];
    Object.keys(sleeplogs)
        .sort((a, b) => moment(a, "YYYY-M-D").isAfter(moment(b), "YYYY-M-D") ? -1 : 1)
        .forEach(key => {
            const oneDaySleeplog = sleeplogs[key];

            const flattedEvents = [];
            // total
            let total = {
                no_sleep: 0,
                sleep_time: 0,
                awake_on_bed: 0,
                tts: 0,
                ls: 0,
                ds: 0,
            };

            // check data
            if (Object.keys(oneDaySleeplog).length === 0) {
                // push day by day
                // result.push({
                //     day: key,
                //     events: [],
                //     total
                // });

                // process vital sign
                // vitalSign.push({
                //     day: key,
                //     dataPointsBr: [],
                //     dataPointsHr: [],
                //     exceedEventsBr: [],
                //     exceedEventsHr: [],
                //     apneaEvents: [],
                //     config_history: {},
                //     highBpmCnt: 0,
                //     lowBpmCnt: 0,
                //     highRpmCnt: 0,
                //     lowRpmCnt: 0,
                //     apneaCnt: 0,
                //     minHr: "--",
                //     minBr: "--",
                //     maxHr: "--",
                //     maxBr: "--",
                // });

                return;
            }

            // event ls, ds
            let sleepEventName, sleepStartTime;
            let lsEvents = [];
            let dsEvents = [];
            let dataPointsBr = [];
            let dataPointsHr = [];
            for (let i = 0; i < 1440; i++) {
                dataPointsBr[i] = undefined;
                dataPointsHr[i] = undefined;
            }
            Object.keys(oneDaySleeplog?.data || {}).forEach((minuteKey, idx) => {
                const minuteData = oneDaySleeplog.data[minuteKey];
                dataPointsBr[Number(minuteKey)] = minuteData.b;
                dataPointsHr[Number(minuteKey)] = minuteData.h;

                if (idx === 0) {
                    sleepEventName = minuteData.s;
                    sleepStartTime = Number(minuteKey);
                }

                switch (minuteData.s) {
                    case 0:
                        total.no_sleep += 1 * 60;
                        break;
                    case 1:
                        if (sleepEventName !== minuteData.s || minuteData.e?.length > 0) {
                            lsEvents.push({
                                type: "LIGHT_SLEEP",
                                startTime: sleepStartTime * 60,
                                duration: (Number(minuteKey) - sleepStartTime) * 60,
                            });
                            sleepEventName = minuteData.s;
                            sleepStartTime = Number(minuteKey);
                        }
                        total.ls += 1 * 60;
                        break;
                    case 2:
                        if (sleepEventName !== minuteData.s || minuteData.e?.length > 0) {
                            dsEvents.push({
                                type: "DEEP_SLEEP",
                                startTime: sleepStartTime * 60,
                                duration: (Number(minuteKey) - sleepStartTime) * 60,
                            });
                            sleepEventName = minuteData.s;
                            sleepStartTime = Number(minuteKey);
                        }
                        total.ds += 1 * 60;
                        break;
                }

                minuteData.e.forEach(ev => {
                    flattedEvents.push({
                        type: EVENTS[ev[0]],
                        num: ev[0],
                        startInSeconds: minuteKey * 60,
                        offsetInSeconds: minuteKey * 60 + ev[1] * 2,
                    });
                });
            });
            // console.log("flattedEvents", flattedEvents)
            // calculate events

            let events = [];
            let eventName, eventNum, lastTime, startTime;
            flattedEvents.forEach((ev, idx) => {
                if (ev.type?.indexOf("_START") > -1) {
                    eventName = ev.type?.replace("_START", "");
                    eventNum = ev.num;
                    lastTime = ev.offsetInSeconds;
                    startTime = ev.offsetInSeconds;
                } else if (ev.type?.indexOf("_END") > -1 && eventNum === ev.num - 1) {
                    lastTime = ev.offsetInSeconds;
                    events.push({
                        type: EVENT_NAME_MAP_TO_LIB[eventName],
                        startTime: startTime,
                        lastTime: lastTime,
                        duration: lastTime - startTime,
                    });
                    eventName = ev.type;
                    eventNum = ev.num;
                    startTime = ev.offsetInSeconds;
                }
            });

            // calc total for sleep states
            total.sleep_time = total.ls + total.ds;

            // calc total for events
            const apneaEvents = [];
            events.forEach(ev => {
                if (ev.type === "APNEA") {
                    apneaEvents.push(ev);
                }
                total[EVENTS_TOTAL_MAP[ev.type]] += ev.duration;
            });

            // push day by day
            result.push({
                day: key,
                events: [...events, ...lsEvents, ...dsEvents].sort((a, b) => a.startTime > b.startTime ? 1 : -1),
                total
            });

            // exceed events
            const exceedEventsBr = [], exceedEventsHr = [];
            const config_history = oneDaySleeplog?.config_history;

            const revert_keys_config_history = Object.keys(config_history).sort((a, b) => a > b ? -1 : 1);
            const brThresholdLow = [], hrThresholdLow = [], brThresholdHigh = [], hrThresholdHigh = [];
            for (let i = 0; i < 1440; i++) {
                const findKey = revert_keys_config_history.find(k => Number(k) <= i);
                brThresholdLow.push(config_history[findKey].rpm_low);
                brThresholdHigh.push(config_history[findKey].rpm_high);
                hrThresholdLow.push(config_history[findKey].bpm_low);
                hrThresholdHigh.push(config_history[findKey].bpm_high);
            }

            let flatEventBr = [], flatEventHr = [];
            dataPointsBr.forEach((d, idx) => {
                // console.log(d, brThresholdLow[idx], brThresholdHigh[idx])
                if (d > 0 && d < Number(brThresholdLow[idx])) {
                    flatEventBr.push({type: "LOW_RESP", startInSeconds: idx * 60, val: d, th: brThresholdLow[idx]});
                } else if (d > Number(brThresholdHigh[idx])) {
                    flatEventBr.push({type: "HIGH_RESP", startInSeconds: idx * 60, val: d, th: brThresholdHigh[idx]});
                }
            });
            dataPointsHr.forEach((d, idx) => {
                // console.log(d, hrThresholdLow[idx], hrThresholdHigh[idx])
                if (d > 0 && d < Number(hrThresholdLow[idx])) {
                    flatEventHr.push({type: "LOW_PR", startInSeconds: idx * 60, val: d, th: hrThresholdLow[idx]});
                } else if (d > Number(hrThresholdHigh[idx])) {
                    flatEventHr.push({type: "HIGH_PR", startInSeconds: idx * 60, val: d, th: hrThresholdHigh[idx]});
                }
            });

            // calc flatEvent
            let brEventName, brStartTime, brLastTime;
            flatEventBr.forEach((br, idx) => {
                if (idx === 0) {
                    brEventName = br.type;
                    brStartTime = br.startInSeconds;
                    brLastTime = br.startInSeconds;
                } else if (brEventName !== br.type || br.startInSeconds - brLastTime > 60) {
                    exceedEventsBr.push({
                        type: brEventName,
                        startTime: brStartTime,
                        duration: brLastTime !== brStartTime ? brLastTime - brStartTime : 60
                    });
                    brEventName = br.type;
                    brStartTime = br.startInSeconds;
                    brLastTime = br.startInSeconds;
                } else if (idx === flatEventBr.length - 1) {
                    exceedEventsBr.push({
                        type: brEventName,
                        startTime: brStartTime,
                        duration: br.startInSeconds - brStartTime
                    });
                } else {
                    brLastTime = br.startInSeconds;
                }
            });

            let hrEventName, hrStartTime, hrLastTime;
            flatEventHr.forEach((hr, idx) => {
                if (idx === 0) {
                    hrEventName = hr.type;
                    hrStartTime = hr.startInSeconds;
                    hrLastTime = hr.startInSeconds;
                } else if (hrEventName !== hr.type || hr.startInSeconds - hrLastTime > 60) {
                    exceedEventsHr.push({
                        type: hrEventName,
                        startTime: hrStartTime,
                        duration: hrLastTime !== hrStartTime ? hrLastTime - hrStartTime : 60
                    });
                    hrEventName = hr.type;
                    hrStartTime = hr.startInSeconds;
                    hrLastTime = hr.startInSeconds;
                } else if (idx === flatEventHr.length - 1) {
                    exceedEventsHr.push({
                        type: hrEventName,
                        startTime: hrStartTime,
                        duration: hr.startInSeconds - hrStartTime
                    });
                } else {
                    hrLastTime = hr.startInSeconds;
                }
            });

            // count
            const highBpmCnt = exceedEventsBr.filter(br => br.type === "HIGH_RESP").length;
            const lowBpmCnt = exceedEventsBr.filter(br => br.type === "LOW_RESP").length;
            const highRpmCnt = exceedEventsHr.filter(br => br.type === "HIGH_PR").length;
            const lowRpmCnt = exceedEventsHr.filter(br => br.type === "LOW_PR").length;
            const apneaCnt = apneaEvents.length;

            // max, min
            let maxBr = -9999, maxHr = -9999, minBr = 9999, minHr = 9999;
            dataPointsBr.forEach(d => {
                if (maxBr < d && d > 0) {
                    maxBr = d;
                }

                if (minBr > d && d > 0) {
                    minBr = d;
                }
            });

            dataPointsHr.forEach(d => {
                if (maxHr < d && d > 0) {
                    maxHr = d;
                }

                if (minHr > d && d > 0) {
                    minHr = d;
                }
            });


            // process vital sign
            vitalSign.push({
                day: key,
                dataPointsBr,
                dataPointsHr,
                exceedEventsBr,
                exceedEventsHr,
                apneaEvents,
                config_history,
                highBpmCnt,
                lowBpmCnt,
                highRpmCnt,
                lowRpmCnt,
                apneaCnt,
                minHr,
                minBr,
                maxHr,
                maxBr,
            })
        });

    return {sl: result, vitalSign: vitalSign};
}

const EVENTS = {
    1: "TTS_START",
    2: "TTS_END",
    3: "AWAKE_START",
    4: "AWAKE_END",
    5: "OFF_BED_START",
    6: "OFF_BED_END",
    7: "APNEA_START",
    8: "APNEA_END",
};

const EVENTS_TOTAL_MAP = {
    "TIME_TO_SLEEP": "tts",
    "AWAKE_ON_BED": "awake_on_bed",
};

const EVENT_NAME_MAP_TO_LIB = {
    "TTS": "TIME_TO_SLEEP",
    "SLEEP_TIME": "SLEEP_TIME",
    "AWAKE": "AWAKE_ON_BED",
    "LIGHT_SLEEP": "LIGHT_SLEEP",
    "DEEP_SLEEP": "DEEP_SLEEP",
    "APNEA": "APNEA",
};

export default reportReducer;