import React from "react";
import {FormattedMessage} from "react-intl";

export function messageWrapper(id, values = {}, defaultMessage) {
    return (<FormattedMessage id={id} values={values} defaultMessage={defaultMessage}/>)
}

export function messageString(Element, id, onChange, defaultMessage) {
    return <FormattedMessage id={id} defaultMessage={defaultMessage}>
        {
            placeholder => <Element placeholder={placeholder} onChange={onChange}/>
        }
    </FormattedMessage>;
}

export function translateInput(inputProps, id, onChange) {
    return <FormattedMessage id={id}>
        {
            placeholder => <input {...inputProps} placeholder={placeholder} onChange={onChange}/>
        }
    </FormattedMessage>;
}

export function translateDefaultOption(id) {
    return <FormattedMessage id={id}>
        {
            text => <option disabled selected hidden>{text}</option>
        }
    </FormattedMessage>;
}

export function translateValueOption(id, value, isDisabled) {
    return <FormattedMessage id={id}>
        {
            text => <option value={value} disabled={isDisabled}>{text}</option>
        }
    </FormattedMessage>;
}