import {POLLING_DATA} from "../actions/liveTileAction";
import moment from "moment";
import {PATIENT_STATUS} from "../../utils/pos-status";

const initialState = {};

const offSoundTemporary = {};

const DUMMIES = [
    {br: 0x19, hr: 0x4F, pos: 0x00, status: 0x00},
    {br: 0x1E, hr: 0x6E, pos: 0x00, status: 0x00},
    {br: 0xFF, hr: 0xFF, pos: 0x02, status: 0x02},
    {br: 0x19, hr: 0x4F, pos: 0x00, status: 0x04},
    {br: 0x0F, hr: 0x3C, pos: 0x00, status: 0x03},
    {br: 0x00, hr: 0x00, pos: 0x00, status: 0x0F},
    {br: 0xFF, hr: 0xFF, pos: 0x00, status: 0x05},
    {br: 0x19, hr: 0x4F, pos: 0x00, status: 0x03},
    {br: 0x19, hr: 0x4F, pos: 0x01, status: 0x10},
    {br: 0xFF, hr: 0xFF, pos: 0x02, status: 0x02},
    {br: 0xFB, hr: 0xFB, pos: 0xFB, status: 0xFB},
    {br: 0xFB, hr: 0xFB, pos: 0xFB, status: 0xFB},
    {br: 0xFB, hr: 0xFB, pos: 0xFB, status: 0xFB},
    {br: 0xFB, hr: 0xFB, pos: 0xFB, status: 0xFB},
    {br: 0xFB, hr: 0xFB, pos: 0xFB, status: 0xFB},
    {br: 0xFB, hr: 0xFB, pos: 0xFB, status: 0xFB},
    {br: 0xFB, hr: 0xFB, pos: 0xFB, status: 0xFB},
];

const armedMap = {};

const ARMED_STATE = {
    NOT_ARMED: "NOT_ARMED",
    ARMED: "ARMED",
    PAUSED: "PAUSED",
    TRIGGERED: "TRIGGERED",
};

const liveTileReducer = (state = initialState, action) => {
    switch (action.type) {
        case POLLING_DATA:

            if (!action.payload) {
                return {...state, time: new Date()};
            }

            const {tiles, nurses, patients} = action.payload;

            // mapping patient
            let patientMap = state.patientMap || {};
            // if(!patientMap) {
            //     patientMap = {};
            patients.forEach(p => {
                patientMap[p.id] = p.name;
            });
            // }

            // mapping nurse
            // let nurseMap = state.nurseMap;
            // if(!nurseMap) {
            //     nurseMap = {};
            //     nurses.forEach(n => {
            //         nurseMap[n.id] = n.name;
            //     });
            // }

            // tile extract
            const timezone = localStorage.getItem("OAH_TIMEZONE");
            const now = moment().utcOffset(timezone).valueOf();
            const newTiles = tiles
                .filter(t => t.patient_id && t.patient_id !== "" && t.data && t.bcu_wifi_mac)
                // .map((t, idx) => ({...t, data: {bed: DUMMIES[idx]}}))
                .map(t => {
                    let status = t.data?.bed;

                    // armed
                    const armed = armedMap[t.bed_name] || {
                        previousState: undefined,
                        currentState: undefined,
                        armed: ARMED_STATE.NOT_ARMED,
                    };

                    armed.previousState = armed.currentState;
                    armed.currentState = status.status;

                    if (
                        armed.armed === ARMED_STATE.NOT_ARMED
                        // && (armed.previousState === PATIENT_STATUS.TimeToSleep || armed.previousState === PATIENT_STATUS.Judgement)
                        && (armed.currentState === PATIENT_STATUS.Sleeping)) {
                        armed.armed = ARMED_STATE.ARMED;
                    } else if (armed.armed === ARMED_STATE.ARMED && (armed.currentState === PATIENT_STATUS.Sleeping
                        || armed.currentState === PATIENT_STATUS.Movement || armed.currentState === PATIENT_STATUS.Awake
                        || armed.currentState === PATIENT_STATUS.Judgement || armed.currentState === PATIENT_STATUS.EOL)) {
                        armed.armed = ARMED_STATE.ARMED;
                    } else if (armed.armed === ARMED_STATE.ARMED && armed.currentState === PATIENT_STATUS.Absent) {
                        armed.armed = ARMED_STATE.TRIGGERED;
                    } else if (armed.armed === ARMED_STATE.TRIGGERED) {
                        const status = t.alarm_status.find(st => st.type === "OFF_BED");
                        if (armed.currentState === PATIENT_STATUS.TimeToSleep || armed.currentState === PATIENT_STATUS.Judgement) {
                            armed.armed = ARMED_STATE.PAUSED;
                        } else if (status && !status.sound) {
                            armed.armed = ARMED_STATE.NOT_ARMED;
                        }
                    } else if (armed.armed === ARMED_STATE.PAUSED && armed.currentState === PATIENT_STATUS.Absent) {
                        armed.armed = ARMED_STATE.TRIGGERED;
                    } else if (armed.armed === ARMED_STATE.PAUSED && armed.currentState === PATIENT_STATUS.Sleeping) {
                        armed.armed = ARMED_STATE.ARMED;
                    }

                    armedMap[t.bed_name] = armed;

                    const valueKeys = ["hr", "br", "pos", "status"];
                    const mapTypeToKey = {
                        "EOL": "end_of_life_enabled",
                        "OFF_BED": "off_bed_enabled",
                        "AWAKE": "awake_enabled",
                    };

                    // if (!t.data) {
                    //     return {
                    //         patient_name: patientMap[t.patient_id],
                    //         bed_name: t.bed_name,
                    //         config: t.config,
                    //         status: {},
                    //         alarm_status: t.alarm_status,
                    //         bcu_ble_mac: t.bcu_ble_mac,
                    //         bcu_wifi_mac: t.bcu_wifi_mac,
                    //         tag_mac: t.tag_mac,
                    //         create_on: t.create_on,
                    //         last_update: t.last_update,
                    //         mat_serial: t.mat_serial,
                    //         hasError: false,
                    //         isExceedHr: false,
                    //         isExceedBr: false,
                    //         hasSound: false,
                    //         isCleared: false,
                    //         audibleActionError: false,
                    //         soundArray: false,
                    //         enableAll: false,
                    //         offSound: false
                    //     };
                    // }

                    // check sound
                    let hasSound = false, audibleActionError = false, soundArray = [], enableAll = false;
                    let isAwake = false;
                    t.alarm_status.forEach(alarm => {
                        const config_key = mapTypeToKey[alarm.type];
                        let enabled = t.config[config_key] === true || t.config[config_key] === "true";

                        // validate off bed range time
                        if (alarm.type === "OFF_BED" && enabled) {
                            const now = moment();
                            let obFrom = moment({
                                hour: t.config.off_bed_from_hour,
                                minute: t.config.off_bed_from_minute,
                                second: 0,
                            });
                            let obTo = moment({
                                hour: t.config.off_bed_to_hour,
                                minute: t.config.off_bed_to_minute,
                                second: 59,
                            });

                            if (obTo.isBefore(obFrom)) {
                                obFrom = obFrom.add(-1, "days");
                            }

                            enabled = now.isBetween(obFrom, obTo) || (obFrom.hours() === obTo.hours() && obFrom.minutes() === obTo.minutes());

                            // armed
                            if (armed.armed !== ARMED_STATE.TRIGGERED) {
                                enabled = false;
                            }
                        }

                        // if(!enableAll && alarm.type !== "AWAKE") {
                        if (!enableAll) {
                            enableAll = enabled;
                        }

                        if (enabled && !hasSound) {
                            hasSound = alarm.sound;
                        }

                        if (enabled && alarm.sound) {
                            soundArray.push(alarm.type);
                        }

                        if (!audibleActionError) {
                            audibleActionError = alarm.status;
                        }

                        if (alarm.type === "AWAKE" && alarm.sound && alarm.status && enabled) isAwake = true;
                    });

                    // let hasError;
                    if (status) {
                        // check has error
                        // hasError = Object.keys(status).filter(k => {
                        //     // if(!hasSound && (
                        //     //     k === "pos" && status[k] === 2
                        //     //     || k === "status" && status[k] === 2
                        //     //     || k === "status" && status[k] === 15
                        //     // )) {
                        //     //     isCleared = true;
                        //     // }
                        //     return valueKeys.indexOf(k) > -1
                        //         && (
                        //             status[k] === 254
                        //             || status[k] === 251
                        //             // || status[k] === 240
                        //             || (k === "pos" && status[k] === 2)
                        //         );
                        // }).length > 0;

                        // check data not update
                        // 02/03/2022
                        const lastUpdate = status.last_update;
                        if (now - lastUpdate > 35000) {
                            status = undefined;
                        }
                        // console.log("bed_name: ", t.bed_name ,", last_update: ",lastUpdate, ", now: ", now, ", now - last_update:", now - lastUpdate);
                    }

                    // check exceed
                    const isExceedHr = isExceed("hr", status?.hr, t.config);
                    const isExceedBr = isExceed("br", status?.br, t.config);

                    // offSoundTemporary
                    const offSound = offSoundTemporary[t.id];
                    if (hasSound) {
                        offSoundTemporary[t.id] = true;
                    } else {
                        delete offSoundTemporary[t.id];
                    }

                    return {
                        patient_name: patientMap[t.patient_id],
                        bed_name: t.bed_name,
                        config: t.config,
                        status: status,
                        alarm_status: t.alarm_status,
                        bcu_ble_mac: t.bcu_ble_mac,
                        bcu_wifi_mac: t.bcu_wifi_mac,
                        tag_mac: t.tag_mac,
                        create_on: t.create_on,
                        last_update: t.last_update,
                        mat_serial: t.mat_serial,
                        isExceedHr,
                        isExceedBr,
                        hasSound,
                        audibleActionError,
                        soundArray,
                        enableAll,
                        offSound,
                        isAwake,
                    };
                })
                .sort((t1, t2) => {
                    // if t1 has sound keep at top
                    if (t1.audibleActionError && t2.audibleActionError && t1.hasSound && t2.hasSound) {
                        // both have sound
                        if (t1.hasSound && t2.hasSound) {
                            return t1.bed_name > t2.bed_name ? 1 : -1;
                        }
                        // default
                        return t1.bed_name > t2.bed_name ? 1 : -1;
                    } else if (t1.audibleActionError && t1.hasSound) {
                        return -1;
                        // if t2 has sound push to top
                    } else if (t2.audibleActionError && t2.hasSound) {
                        return 1;
                        //     // if both tile has error, push to top left by bed name
                        // } else if (t1.hasError) {
                        //     return -1;
                        //     // if t2 has sound push to top
                        // } else if (t2.hasError) {
                        //     return 1;
                        //     // if both tile has error, push to top left by bed name
                        // } else if (t1.hasError && t2.hasError) {
                        //     return t1.bed_name > t2.bed_name ? 1 : -1;
                        //     // } else if (t1.hasError && !t1.isCleared) {
                        //     //     // if tile 1 has error, push to tile 1 to top left
                        //     //     return -1;
                        //     //     // if tile 2 has error, push to tile 2 to top left
                        //     // } else if (t2.hasError && !t1.isCleared) {
                        //     //     return 1;
                        // } else if ((t1.isExceedBr || t1.isExceedHr) && (!t2.isExceedBr && !t2.isExceedHr)) {
                        //     return -1;
                        // } else if ((t2.isExceedBr || t2.isExceedHr) && (!t1.isExceedBr && !t1.isExceedHr)) {
                        //     return 1;
                        //     // } else if (t1.isCleared) {
                        //     //     return -1;
                        //     // } else if (t2.isCleared) {
                        //     //     return 1;
                        // } else if (!t1.hasError && !t2.hasError) {
                        //     // if both tiles is exceed, push to top left by bed name
                        //     if ((t1.isExceedHr || t1.isExceedBr) && (t2.isExceedHr || t2.isExceedBr)) {
                        //         return t1.bed_name > t2.bed_name ? 1 : -1;
                        //     }
                        //     // if t1 is exceed, push t1 to top left, else push t2 to top left
                        //     return (t1.isExceedBr || t1.isExceedHr) ? -1 : 1;
                    } else {
                        return t1.bed_name > t2.bed_name ? 1 : -1;
                    }
                });

            const hasSound = newTiles.filter(t => t.hasSound && t.offSound === undefined);

            // alert image in main tab
            const hasAlert = newTiles.filter(tile => {
                return tile.isExceedHr || tile.isExceedBr || tile.hasSound || tile.hasError || tile.audibleActionError;
            }).length;

            if (hasAlert !== state.hasAlert) {
                if (hasAlert > 0) {
                    localStorage.setItem("OMNI2_ALERT", "true");
                } else {
                    localStorage.setItem("OMNI2_ALERT", "false");
                }
            }

            return {...state, tiles: newTiles, time: new Date(), hasSound: hasSound.length > 0, hasAlert, armedMap};
        default:
            return state
    }
};

function isExceed(field, val, config) {
    switch (field) {
        case "hr":
            return val !== 255 && (val < config?.bpm_low || val > config?.bpm_high);
        case "br":
            return val !== 255 && (val < config?.rpm_low || val > config?.rpm_high);
        default:
            return false;
    }
}

export default liveTileReducer;