import React, {useEffect, useState} from "react";
import {useAuth} from "../../../connections/net";
import {messageWrapper as t, translateValueOption} from "../../../utils/message-wrapper";
import {EspTable} from "../../common/EspTable";
import moment from "moment";
import {IrespDropdown} from "../../common/IrespDropdown";
import _ from "lodash";
import "./Dashboard.css";
import {Sort} from "../../../constants/sort";
import {compareOACBedName} from "../../../utils/sort";
import addImg from "../../../images/oac/add-button.png"
import {OACSearchField} from "../../common/oac/OACSearchField";
import {IrespModal} from "../../common/IrespModal";
import {EspDropdown} from "../../common/EspDropdown";
import {OACSortIcon} from "../../common/oac/OACSortIcon";
import {OACFilter} from "../../common/oac/OACFilter";
import {OACPagingSelection} from "../../common/oac/OACPagingSelection";
import {OACPaging} from "../../common/oac/OACPaging";
import {isValidEmail} from "../../../utils/validation";


const TextFields = [""];

export function Dashboard(props) {

    useAuth();

    const [state, setState] = useState({tab: 1, patients: [], page: 1, sortField: "oah_id", sortOrder: 1, itemsPerPage: 10, selectedCountry: {value: "JP", label: t("JP")}});

    useEffect(() => {
        switch (state.tab) {
            case 1:
                props.getListOfCC();
                props.getListOfCountry();
                break;
            case 2:
                // props.getBedAssignment();
                break;
        }

    }, [state.tab]);

    useEffect(() => {
        if (props.listOfCC) {
            const filterCC = [...props.listOfCC]
                .filter(b => {
                    return true;
                })
                .sort((a, b) => {
                    let dataOrder = 0;
                    if (!a[state.sortField]) dataOrder = 1;
                    else if (!b[state.sortField]) dataOrder = -1;
                    else dataOrder = a[state.sortField] <= b[state.sortField] ? 1 : -1;
                    return dataOrder * state.sortOrder;
                });
            const itemsCCThisPage = [...filterCC].splice((state.page - 1) * state.itemsPerPage, state.itemsPerPage);
            setState({...state, filterCC: filterCC, itemsCCThisPage});
        }
    }, [props.listOfCC, state.page, state.itemsPerPage, state.sortField, state.sortOrder]);

    const generateCareCenterTable = () => {
        const generateHeader = () => {
            return <tr>
                <td>
                    {t(`CC ID`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "oah_id")}/>
                </td>
                <td>
                    {t(`CC Name`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "oah_name")}/>
                </td>
                <td>
                    {t(`Display Name`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "display_name")}/>
                </td>
                <td>
                    {t(`Address`)}
                     <OACSortIcon handleSort={(order) => handleSort(order, "address")}/>
                </td>
                <td>
                    {t(`Contact number`)}
                     <OACSortIcon handleSort={(order) => handleSort(order, "oah_contact_number")}/>
                </td>
                <td>
                    {t(`SYS-ADMIN Email`)}
                     <OACSortIcon handleSort={(order) => handleSort(order, "manager")}/>
                </td>
                <td>
                    {t(`NURSE-ADMIN Email`)}
                     <OACSortIcon handleSort={(order) => handleSort(order, "admin")}/>
                </td>
                <td>
                    {t(`Account Control`)}
                     <OACSortIcon handleSort={(order) => handleSort(order, "enabled")}/>
                </td>
                <td>
                    {t(`Action`)}
                </td>
            </tr>
        };
        const generateBody = () => {
            if (!state.itemsCCThisPage || state.itemsCCThisPage.length < 1) {
                return <tr>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                return state.itemsCCThisPage
                    // .sort((a, b) => compareOACBedName(a.oah_id, b.oah_id))
                    .map(b => {
                        return <tr>
                            <td>{b.oah_id}</td>
                            <td>{b.oah_name}</td>
                            <td>{b.display_name}</td>
                            <td>{b.address}</td>
                            <td>{b.oah_contact_number}</td>
                            <td>{b.manager}</td>
                            <td>{b.admin}</td>
                            <td>{b.enabled ? t(`Enabled`) : t(`Disabled`)}</td>
                            <td>{generateAction(b)}</td>
                        </tr>
                    });
            }
        };

        return <>
            <div className="table-descr">
                <div className="inline w780">
                    {t(`Display`)}
                    &nbsp;<OACPagingSelection handleChangePage={handleChangePage}/>&nbsp;
                    {t(`records per page`)}
                </div>
                <div className="inline w19p">

                </div>
                <div className="inline ml-600">
                    <span className="link" onClick={() => setState({...state, add: {}, isOpenAddModal: true})}>
                        <img src={addImg}/>
                        {t(`Add Care Centre`)}
                    </span>
                </div>
                <div className="inline right">
                    <span className="link">
                        <OACSearchField inputClazz="w325px"
                                        onChange={(e) => setState({...state, search: e.target?.value})}/>
                    </span>
                </div>
            </div>
            <EspTable className="view-cc" renderHeader={generateHeader}
                      renderBody={generateBody}/>
            <OACPaging
                total={state.filterCC?.length}
                itemsPerPage={state.itemsPerPage}
                page={state.page}
                setPage={(page) => setState({...state, page: page})}
            />
        </>
    };

    const generateAction = (cc) => {
        return <span>
             <a className="link" onClick={() => setState({...state, edit: cc, selectedCountry: {value: cc.country_code, label: t(cc.country_code || "JP")}, isOpenEditModal: true})}>{t(`Edit`)}</a>
            &nbsp;/&nbsp;
            <a className={cc.beds > 0 ? "link disabled" : "link"}
               onClick={() => setState({...state, delete: cc, isOpenDeleteModal: true})}>{t(`Delete`)}</a>
        </span>
    };

    const generateRenewAction = () => {
        return <span>
             <a className="link">{t(`Renew`)}</a>
        </span>
    };

    const dropdownValues = () => {
        if (!state.filterField) return [];
        // if(state.filterField.indexOf("patient") > -1) {
        //     return _.uniq(props.patients?.map(b => b.name));
        // }
        if (state.filterField.indexOf("nurse_names") > -1) {
            const values = {};
            props.beds.forEach(b => {
                const nurses = b[state.filterField] || [];
                nurses.forEach(s => values[s] = true);
            });
            return Object.keys(values).sort((a, b) => a > b ? 1 : -1);
        }

        if (state.filterField.indexOf("status") > -1) {
            const values = {};
            props.beds.forEach(b => {
                const statuses = b[state.filterField] || [];
                statuses.forEach(s => values[s] = true);
            });
            return Object.keys(values).sort((a, b) => a > b ? 1 : -1);
        }

        return _.uniq(props.beds.map(b => b[state.filterField]), state.filterField).sort((a, b) => a.toUpperCase() > b.toUpperCase() ? 1 : -1)
    };

    const generateValues = () => {
        if (TextFields.indexOf(state.filterField) > -1) {
            return <input className="filter" value={state.filterValue || ""}
                          onChange={e => setState({...state, filterValue: e.target.value})}/>
        }

        return <IrespDropdown
            value={state.filterValue}
            options={[
                `Select Value`,
                translateValueOption("All", "ALL"),
                ...dropdownValues().map(val => {
                    if (state.filterField.indexOf("nurse_id") > -1) {
                        return <option
                            value={props.nurseMap[val]}>{props.nurseMap[val]}</option>;
                    }
                    return <option value={val}>{val}</option>;
                })
            ]} handleSelect={(val) => setState({...state, filterValue: val})}/>;
    };

    const validate = (data) => {
        const required_fields = [
            "oah_name",
            "display_name",
            "address",
            "manager",
            "admin",
            "country_code"
        ];

        let isInvalid = false;

        required_fields.forEach((f, idx) => {
            if (!data || !data[required_fields[idx]] || data[required_fields[idx]] === "") {
                isInvalid = true;
            }
        });

        if (isInvalid) {
            props.setError("* mandatory fields");
            return false;
        }

        const sysEmailValid = isValidEmail(data.manager);
        const nurseEmailValid = isValidEmail(data.admin);

        if (!sysEmailValid) {
            props.setError("SYS-ADMIN email address invalid");
            return false;
        }

        if (!nurseEmailValid) {
            props.setError("NURSE-ADMIN email address invalid");
            return false;
        }

        return true;
    };

    const handleAddConfirm = async () => {
        const isValid = validate({...state.add, country_code: state.selectedCountry?.value});
        if (isValid) {
            const res = await props.addCC({
                "oah_contact": state.add?.contact,
                "manager_email" : state.add?.manager,
                "admin_email" : state.add?.admin,
                "oah_name" : state.add?.oah_name,
                "oah_address" :  state.add?.address,
                "notes" :  state.add?.notes,
                "display_name" : state.add?.display_name,
                "oah_contact_name" : state.add?.contact_name,
                "oah_contact_number" : state.add?.contact_number,
                "country_code" : state.selectedCountry?.value,
            });

            if (res) {
                setState({...state, isOpenAddModal: false});
            }
        }
    };

    const handleAddCancel = () => {
        setState({...state, isOpenAddModal: false});
        props.setError();
    };

    const handleEditConfirm = async () => {
        const isValid = validate({...state.edit, country_code: state.selectedCountry?.value});
        if (isValid) {
            const res = await props.updateCC({
                "oah_contact_number": state.edit?.oah_contact_number,
                "oah_contact_name": state.edit?.oah_contact_name,
                "display_name": state.edit?.display_name,
                "notes": state.edit?.notes,
                "oah_id": state.edit?.oah_id,
                "admin_email": state.edit?.admin,
                "manager_email": state.edit?.manager,
                "oah_name": state.edit?.oah_name,
                "oah_address": state.edit?.address,
                "country_code": state.selectedCountry?.value,
                "oah_enabled": true
            });

            if (res) {
                setState({...state, isOpenEditModal: false});
            }
        }
    };

    const handleEditCancel = () => {
        setState({...state, isOpenEditModal: false});
        props.setError();
    };

    const handleDeleteConfirm = async () => {
        const res = await props.deleteCC({
            "oah_id": state.delete?.oah_id,
        });

        if (res) {
            setState({...state, isOpenDeleteModal: false});
        }
    };

    const handleDeleteCancel = () => {
        setState({...state, isOpenDeleteModal: false});
    };

    const handleAddChange = (e) => {
        setState({...state, add: {...state.add, [e.target.name]: e.target.value}});
    };

    const handleEditChange = (e) => {
        setState({...state, edit: {...state.edit, [e.target.name]: e.target.value}});
    };

    const handleSearch = () => {
        setState({...state, isSearch: true});
    };

    const handleChangePage = (itemsPerPage) => {
        setState({...state, itemsPerPage: itemsPerPage})
    };

    const handleSort = (order, field) => {
        setState({...state, sortOrder: order, sortField: field});
    };

    return (
        <div className="care-center-management">
            <div className="data">
                <div className="tabs">
                    <a onClick={() => setState({...state, tab: 1, filterField: undefined})}
                       className={state.tab === 1 ? "selected" : ""}>{t(`Care Centres`)}</a>
                </div>
                <div className="devider"></div>
            </div>
            {
                state.tab === 2
                    ? <div className="">
                        <OACFilter listOfCountries={[]} handleSearch={handleSearch}/>
                    </div>
                    : ""
            }
            {
                state.tab === 1
                    ? <div className="table-content">
                        <div>{generateCareCenterTable()}</div>
                    </div>
                    : ""
            }
            {
                state.isOpenAddModal && <IrespModal
                    clazz="add-csp-modal"
                    title={t(`Add Care Centre`)}
                    contents={[
                        <div className="mt20">
                            <div className="inline w223px">
                                <span className="color-red">*&nbsp;</span>{t(`Company Name:`)}
                            </div>
                            <div className="inline">
                                <textarea maxLength={50} value={state.add?.oah_name} name="oah_name"
                                          onChange={handleAddChange} className="lg placeholder-right"/>
                                <label className="label-holder">{`${state.add?.oah_name?.length || 0} / 50`}</label>
                            </div>
                        </div>,
                        <div className="mt5">
                            <div className="inline w223px">
                                <span className="color-red">*&nbsp;</span>{t(`Display Name:`)}
                            </div>
                            <div className="inline">
                                <input maxLength={20} value={state.add?.display_name} name="display_name"
                                       onChange={handleAddChange} className="lg placeholder-right"/>
                                <label className="label-holder bottom-10">{`${state.add?.display_name?.length || 0} / 20`}</label>
                            </div>
                        </div>,
                        <div className="mt5">
                            <div className="inline w223px">
                                <span className="color-red">*&nbsp;</span>{t(`Address:`)}
                            </div>
                            <div className="inline">
                                <textarea value={state.add?.address} name="address" onChange={handleAddChange}
                                          className="lg placeholder-right"/>
                            </div>
                        </div>,
                        <div className="mt5">
                            <div className="inline w223px">
                                <span className="color-red">*&nbsp;</span>{t(`Country:`)}
                            </div>
                            <div className="inline">
                                <EspDropdown
                                    key={Math.random()}
                                    value={state.selectedCountry}
                                    placeholder={`Select country`}
                                    options={props.listOfCountries?.map(b => ({
                                        value: b,
                                        label: t(b)
                                    }))}
                                    onChange={(val) => setState({...state, selectedCountry: val})}
                                />
                            </div>
                        </div>,
                        <div className="mt5">
                            <div className="inline w223px">
                                {t(`Contact name:`)}
                            </div>
                            <div className="inline">
                                <input value={state.add?.contact_name} name="contact_name" onChange={handleAddChange}
                                       className="lg"/>
                            </div>
                        </div>,
                        <div className="mt5">
                            <div className="inline w223px">
                                {t(`Contact number:`)}
                            </div>
                            <div className="inline">
                                <input value={state.add?.contact_number} name="contact_number" onChange={handleAddChange}
                                       className="lg"/>
                            </div>
                        </div>,
                        <div className="mt5">
                            <div className="inline w223px">
                                {t(`Notes:`)}
                            </div>
                            <div className="inline">
                                <textarea maxLength={80} value={state.add?.notes} name="notes"
                                          onChange={handleAddChange} className="lg placeholder-right"/>
                                <label className="label-holder">{`${state.add?.notes?.length || 0} / 80`}</label>
                            </div>
                        </div>,
                        <div className="mt20">
                            <div className="inline w223px">
                                <span className="color-red">*&nbsp;</span>{t(`SYS-ADMIN Email:`)}
                            </div>
                            <div className="inline">
                                <input value={state.add?.manager} name="manager" onChange={handleAddChange} className="lg"/>
                            </div>
                        </div>,
                        <div className="mt5">
                            <div className="inline w223px">
                                <span className="color-red">*&nbsp;</span>{t(`NURSE-ADMIN Email:`)}
                            </div>
                            <div className="inline">
                                <input value={state.add?.admin} name="admin" onChange={handleAddChange} className="lg"/>
                            </div>
                        </div>,
                        <div className="mt20">
                            {
                                props.error
                                    ? <div className="error">
                                        {t(props.error)}
                                    </div>
                                    : undefined
                            }
                        </div>,
                    ]}
                    buttons={[
                        <button onClick={() => handleAddCancel()}>
                            {t(`Cancel`)}
                        </button>,
                        <button onClick={() => handleAddConfirm()}>
                            {t(`Confirm`)}
                        </button>,
                    ]}/>
            }
            {
                state.isOpenEditModal && <IrespModal
                    clazz="add-csp-modal"
                    title={t(`Edit Care Centre`)}
                    contents={[
                        <div className="mt20">
                            <div className="inline w223px">
                                {t(`CC ID:`)}
                            </div>
                            <div className="inline">
                                <input readOnly={true} value={state.edit?.oah_id} className="lg"/>
                            </div>
                        </div>,
                        <div className="mt5">
                            <div className="inline w223px">
                                <span className="color-red">*&nbsp;</span>{t(`Company Name:`)}
                            </div>
                            <div className="inline">
                                <textarea maxLength={50} value={state.edit?.oah_name} name="oah_name"
                                          onChange={handleEditChange} className="lg placeholder-right"/>
                                <label className="label-holder">{`${state.edit?.oah_name?.length || 0} / 50`}</label>
                            </div>
                        </div>,
                        <div className="mt5">
                            <div className="inline w223px">
                                <span className="color-red">*&nbsp;</span>{t(`Display Name:`)}
                            </div>
                            <div className="inline">
                                <input maxLength={20} value={state.edit?.display_name} name="display_name"
                                       onChange={handleEditChange} className="lg placeholder-right"/>
                                <label
                                    className="label-holder bottom-10">{`${state.edit?.display_name?.length || 0} / 20`}</label>
                            </div>
                        </div>,
                        <div className="mt5">
                            <div className="inline w223px">
                                <span className="color-red">*&nbsp;</span>{t(`Address:`)}
                            </div>
                            <div className="inline">
                                <textarea value={state.edit?.address} name="address" onChange={handleEditChange}
                                          className="lg placeholder-right"/>
                            </div>
                        </div>,
                        <div className="mt5">
                            <div className="inline w223px">
                                <span className="color-red">*&nbsp;</span>{t(`Country:`)}
                            </div>
                            <div className="inline">
                                <EspDropdown
                                    key={Math.random()}
                                    value={state.selectedCountry}
                                    placeholder={`Select country`}
                                    options={props.listOfCountries?.map(b => ({
                                        value: b,
                                        label: t(b)
                                    }))}
                                    onChange={(val) => setState({...state, selectedCountry: val})}
                                />
                            </div>
                        </div>,
                        <div className="mt5">
                            <div className="inline w223px">
                                {t(`Contact name:`)}
                            </div>
                            <div className="inline">
                                <input value={state.edit?.oah_contact_name} name="oah_contact_name" onChange={handleEditChange}
                                       className="lg"/>
                            </div>
                        </div>,
                        <div className="mt5">
                            <div className="inline w223px">
                                {t(`Contact number:`)}
                            </div>
                            <div className="inline">
                                <input value={state.edit?.oah_contact_number} name="oah_contact_number" onChange={handleEditChange}
                                       className="lg"/>
                            </div>
                        </div>,
                        <div className="mt5">
                            <div className="inline w223px">
                                {t(`Notes:`)}
                            </div>
                            <div className="inline">
                                <textarea maxLength={80} value={state.edit?.notes} name="notes"
                                          onChange={handleEditChange} className="lg placeholder-right"/>
                                <label className="label-holder">{`${state.edit?.notes?.length || 0} / 80`}</label>
                            </div>
                        </div>,
                        <div className="mt20">
                            <div className="inline w223px">
                                <span className="color-red">*&nbsp;</span>{t(`SYS-ADMIN Email:`)}
                            </div>
                            <div className="inline">
                                <input value={state.edit?.manager} name="manager" onChange={handleEditChange}
                                       className="lg"/>
                            </div>
                        </div>,
                        <div className="mt5">
                            <div className="inline w223px">
                                <span className="color-red">*&nbsp;</span>{t(`NURSE-ADMIN Email:`)}
                            </div>
                            <div className="inline">
                                <input value={state.edit?.admin} name="admin" onChange={handleEditChange}
                                       className="lg"/>
                            </div>
                        </div>,
                        <div className="mt20">
                            {
                                props.error
                                    ? <div className="error">
                                        {t(props.error)}
                                    </div>
                                    : undefined
                            }
                        </div>,
                    ]}
                    buttons={[
                        <button onClick={() => handleEditCancel()}>
                            {t(`Cancel`)}
                        </button>,
                        <button onClick={() => handleEditConfirm()}>
                            {t(`Confirm`)}
                        </button>,
                    ]}/>
            }
            {
                state.isOpenDeleteModal
                    ? <IrespModal
                        clazz="delete-cc-modal"
                        title={t(`Delete Care Centre`)}
                        contents={[
                            <div className="note-red">
                                {t(`Note: This action cannot be undone.`)}
                            </div>,
                            <div className="mt50">
                                <div className="inline w160px">
                                    {t(`CC ID:`)}
                                </div>
                                <div className="inline">
                                    <b>{state.delete?.oah_id}</b>
                                </div>
                            </div>,
                            <div className="mt10">
                                <div className="inline w160px">
                                    {t(`CC Name:`)}
                                </div>
                                <div className="inline">
                                    <b>{state.delete?.display_name}</b>
                                </div>
                            </div>,
                            <div className="mt50">
                                <div className="inline w160px">
                                    {t(`Address:`)}
                                </div>
                                <div className="inline">
                                    {state.delete?.address}
                                </div>
                            </div>,
                            <div className="mt10">
                                <div className="inline w160px">
                                    {t(`Contact number:`)}
                                </div>
                                <div className="inline">
                                    {state.delete?.oah_contact_number || "--"}
                                </div>
                            </div>,
                            <div className="mt10">
                                <div className="inline w160px">
                                    {t(`Notes:`)}
                                </div>
                                <div className="inline">
                                    {state.delete?.notes || "--"}
                                </div>
                            </div>,
                            <div className="mt20">
                                {
                                    props.error
                                        ? <div className="error">
                                            {t(props.error)}
                                        </div>
                                        : undefined
                                }
                            </div>,
                        ]}
                        buttons={[
                            <button onClick={handleDeleteCancel}>{t(`Cancel`)}</button>,
                            <button onClick={handleDeleteConfirm}>{t(`Confirm`)}</button>,
                        ]}
                    />
                    : null
            }
        </div>
    );
}