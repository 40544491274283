import React from "react";
import "./IrespModal.css";

export function IrespModal({title, contents = [], buttons = [], clazz}) {
    return (
        <div className={"iresp-modal" + " " + clazz}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title" id="about-title">
                            {title}
                        </div>
                    </div>
                    <div className="modal-body">
                        {contents.map(c => (c))}
                    </div>
                    <div className="modal-footer">
                        {buttons.map(b => (b))}
                    </div>
                </div>
            </div>
        </div>
    );
}