export const Configs = {
    // en = English, ja = Japanese
    LANGUAGE: "ja",
    // false = No include report feature, true = Include report feature
    // HAS_REPORT: true,
    // for disconnection
    NUMBER_OF_RETRIES: 30, // 30 times: number of times to attempt connect to server
    RETRY_INTERVAL: 2, // 2 seconds: delay between 2 retry
    TIME_DELAY_BETWEEN_CONNECTION_RETRIES: 0, // 0 second: wait retry after X*Y

    // APP_TYPE: 2, // 1 = iRESP 1.0, 2 = iRESP 2.0
};