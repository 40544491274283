import React, {useState, useRef, useEffect} from "react";
import "./OACSort.css";

import UpArrow from "../../../images/oac/up-arrow.png";
import DownArrow from "../../../images/oac/down-arrow.png";

export function OACSortIcon(props) {
    const [state, setState] = useState({order: 0});

    let sortIcon = <div className="sort-icon" onClick={() => sort(1)}>
        <img src={UpArrow}/>
        <img src={DownArrow}/>
    </div>;

    if (state.order === 1) {
        sortIcon = <div className="sort-icon" onClick={() => sort(-1)}>
            <img className="invisible" src={UpArrow}/>
            <img src={DownArrow}/>
        </div>;
    } else if (state.order === -1) {
        sortIcon = <div className="sort-icon" onClick={() => sort(0)}>
            <img src={UpArrow}/>
            <img className="invisible" src={DownArrow}/>
        </div>;
    }

    const sort = (order) => {
        setState({...state, order: order});
        props.handleSort(order);
    };


    return (
        <div className="sort-icon-wrapper">
            {sortIcon}
        </div>
    );
}