import {compareOACBedName} from "../../../utils/sort";
import {GET_SCHEDULE_LIST, SET_ERROR} from "../../actions/csp/cspSchedule";

const initialState = {};

const cspScheduleReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SCHEDULE_LIST:
            const bed_list = action.payload;

            return {...state, listOfSchedule: bed_list.sort((a, b) => compareOACBedName(a.oah_id, b.oah_id))};
        case SET_ERROR:
            return {...state, error: action.payload};
        default:
            return state
    }
};

export default cspScheduleReducer;