import {compareOACBedName} from "../../../utils/sort";
import {GET_CC_LIST, LIST_DIAGNOSTICS, LIST_WARRANTY, SET_ERROR} from "../../actions/csp/cspDashboard";
import {GET_COUNTRY_LIST} from "../../actions/csp/cspManagement";

const initialState = {};

const cspDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CC_LIST:
            const oahs = action.payload;
            return {...state, listOfCC: oahs.sort((a, b) => compareOACBedName(a.display_name, b.display_name))};
        case SET_ERROR:
            return {...state, error: action.payload};
        case LIST_WARRANTY:
            const bed_list = action.payload;
            return {...state, listOfWarranty: bed_list.sort((a, b) => compareOACBedName(a.bed_name, b.bed_name))};
        case LIST_DIAGNOSTICS:
            return {...state, listOfDiagnostics: action.payload?.sort((a, b) => compareOACBedName(a.bed_name, b.bed_name))};
        case GET_COUNTRY_LIST:
            const countries = action.payload;
            return {
                ...state,
                listOfCountries: countries.sort((a, b) => compareOACBedName(a.country_code, b.country_code)),
            };
        default:
            return state
    }
};

export default cspDashboardReducer;