import {dataProvider} from "../../../connections/net";
import {appAction} from "../appAction";

export const GET_CC_LIST = "cspManagement/action/GET_CC_LIST";
export const GET_COUNTRY_LIST = "cspManagement/action/GET_COUNTRY_LIST";
export const SET_ERROR = "cspManagement/action/SET_ERROR";
export const SET_STATE = "cspManagement/action/SET_STATE";
export const CLEAR_DATA = "cspManagement/action/CLEAR_DATA";

export const cspManagement = {
    getListOfCC: (info) => async dispatch => {
        dispatch(appAction.openLoading());
        try {
            const {status_code, oahs} = await dataProvider.listCC(info);
            if (status_code === 0 && oahs) {
                dispatch({type: GET_CC_LIST, payload: oahs});
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
        dispatch(appAction.closeLoading());
    },
    addCC: (info) => async dispatch => {
        dispatch(appAction.openLoading());
        let res;
        try {
            const {status_code, error} = await dataProvider.addCC(info);
            if (status_code === 0) {
                dispatch(cspManagement.getListOfCC());
                res = true;
            } else {
                dispatch({type: SET_ERROR, payload: error});
                res = false;
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
        dispatch(appAction.closeLoading());
        return res;
    },
    updateCC: (info) => async dispatch => {
        dispatch(appAction.openLoading());
        let res;
        try {
            const {status_code, error} = await dataProvider.updateCC(info);
            if (status_code === 0) {
                dispatch(cspManagement.getListOfCC());
                dispatch({type: SET_ERROR, payload: undefined});
                res = true;
            } else {
                dispatch({type: SET_ERROR, payload: error});
                res = false;
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
        dispatch(appAction.closeLoading());
        return res;
    },
    deleteCC: (info) => async dispatch => {
        dispatch(appAction.openLoading());
        let res;
        try {
            const {status_code, error} = await dataProvider.deleteCC(info);
            if (status_code === 0) {
                dispatch(cspManagement.getListOfCC());
                res = true;
            } else {
                dispatch({type: SET_ERROR, payload: error});
                res = false;
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
        dispatch(appAction.closeLoading());
        return res;
    },
    getListOfCountry: () => async dispatch => {
        dispatch(appAction.openLoading());
        try {
            const {status_code, countries} = await dataProvider.getListOfCountry();
            if (status_code === 0 && countries) {
                dispatch({type: GET_COUNTRY_LIST, payload: countries});
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
        dispatch(appAction.closeLoading());
    },
    setError: (error) => dispatch => dispatch({type: SET_ERROR, payload: error}),
    setState: (state) => dispatch => dispatch({type: SET_STATE, payload: state}),
    clearData: () => dispatch => dispatch({type: CLEAR_DATA})
};