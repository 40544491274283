import React, {useState, useEffect} from "react";
import {messageWrapper as t, translateInput as ti} from "../../utils/message-wrapper";
import "./Login.css";
import {IrespModal} from "../common/IrespModal";
import {EspDropdown} from "../common/EspDropdown";
import AppIcon from "../../images/oac/favicon_io/favicon-192x192.png";
import cx from "classnames";
import {OACPasswordField} from "../common/oac/OACPasswordField";
import PasswordOk from "../../images/oac/passwordOk.png";
import PasswordNOk from "../../images/oac/passwordNOk.png";
import {ROLES} from "../../constants/roles";

const ROLES_NAME_MAP = {
    OSP: "OSP",
    CSP: "CSP",
};

const initialState = {
    isForgotPassword: false, step: 1,
    role: {value: "OSP", label: "OSP"}
};

export function Login(props) {
    const [state, setState] = useState({...initialState});

    useEffect(() => {
        // props.getCountryCode();
    }, []);

    const handleSignIn = () => {
        // TODO
        // if (state.username === "123" && state.password === "123") {
        //     localStorage.setItem("token", `Bearer faketoken`);
        //     localStorage.setItem("OMNI2_ROLE", ROLES.NurseAdmin);
        //     localStorage.setItem("OMNI2_USER", "test");
        //     localStorage.removeItem("OMNI2_TILES");
        //     window.location.href = "/nurse-admin-dashboard";
        //     return;
        // }
        // let role;
        // if (state.username === "osp" || state.password === "test") {
        //     role = ROLES.OSP;
        // } else if (state.username === "csp") {
        //     role = ROLES.CSP;
        // }
        // localStorage.setItem('token', "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkE6dGVzdF9vYWhAb3NwaWNvbi5jb20iLCJyb2xlIjoiQURNSU4iLCJ0b2tlbiI6IjMxbnFra2xtc2tpdDR2dHUiLCJpYXQiOjE2ODA1OTQ1MDcsImV4cCI6NDgzNjM1NDUwN30.ac1tLOtNUQYJHeyEeaoFxawTl8jOAsN2oOMW0ZODk8c");
        // localStorage.setItem("OMNI2_ROLE", role);
        // if (role === ROLES.CSP) {
        //     window.location.href = "/csp-dashboard";
        // } else {
        //     window.location.href = "/osp-dashboard";
        // }

        // let esp_login = state.esp_login;

        // validate
        if (!state.username || !state.password) {
            props.setError("User_ID & Password cannot be empty");
            return;
        }

        // select phone or email
        // if (state.esp_login.indexOf("@") === -1) {
        //     if (!props.esp_country_code) {
        //         props.setError("Country code is required for login by phone number");
        //         return;
        //     }
        //     const pn = new PhoneNumber(state.esp_login, props.esp_country_code);
        //     esp_login = pn.getNumber();
        // }

        // trigger action
        // if (!localStorage.getItem("token")) {
            props.checkLogin({
                username: state.username,
                password: state.password,
            }, props.history.push);
        // } else {
        //     window.location.href = "/csp-dashboard";
        // }
    };


    // const onSelectFlag = (countryCode) => {
    //     props.setCountryCode(countryCode)
    // };

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    };

    const handleDuplicateLoginCancel = () => {
        props.duplicateLoginResponse(false);
    };

    const handleDuplicateLoginConfirm = () => {
        props.duplicateLoginResponse(false);
        // props.doLogin({username: state.username, password: state.password, oah_user: "ADMIN"}, props.history.push);
        props.doLogin({
            username: state.username,
            password: state.password,
        }, props.history.push);
    };

    const handleForgot = () => {
        setState({...state, isForgotPassword: true, step: 1});
    };

    const handleForgotStep1 = async () => {
        const res = await props.requestResetPassword({
            "username": state.email,
            "oah_user": state.role?.value,
        });
        if (res) {
            setState({...state, isForgotPassword: true, step: 2});
            props.setError(undefined);
        }
    };

    const handleForgotStep2 = () => {
        setState({...state, isForgotPassword: true, step: 3});
    };

    const handleForgotStep3 = () => {
        if (state.password !== state.confirm) {
            props.setError("No match");
            return;
        }
        setState({...state, isForgotPassword: true, step: 4});
    };

    const checkPassword = (cond) => {
        switch (cond) {
            case 1:
                const reg1 = /[\w]{8,}/gm;
                return reg1.test(state.password);
            case 2:
                const reg2 = /[A-Z]/gm;
                return reg2.test(state.password);
            case 3:

                const reg3 = /[a-z]/gm;
                return reg3.test(state.password);
            case 4:
                const reg4 = /[0-9]+/gm;
                return reg4.test(state.password);
            case 5:
                const reg5 = /[!@#\$%\^\&*\)\(+=._-]+/gm;
                return reg5.test(state.password);
        }
    };

    // if (!props.esp_country_code) {
    //     return <div/>
    // }
    const UsernameInput = ti({type: "text", name: "username"}, `Enter User_ID`, handleChange);
    const PasswordInput = ti({type: "password", name: "password"}, `Enter Password`, handleChange);
    const ConfirmPasswordInput = ti({type: "confirm", name: "confirm"}, `Confirm password`, handleChange);
    const EmailInput = ti({type: "email", name: "email"}, `Enter User_ID`, handleChange);

    return (
        <div id="login-container">
            {
                !state.isForgotPassword
                    ? <div id="login-form" className={localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") !== "en" ? "ja" : ""}>
                        <div className="login-header">
                            <img className="logo1" src={AppIcon}/>
                            {/*<span className="logo-text">*/}
                            {/*{Configs.APP_TYPE === 1 ? "OAC (OLD AGED CARE)" : "OAC (OLD AGED CARE)"}*/}
                            {/*</span>*/}
                            {/*<img className="logo1" src={LogoImage}/>*/}
                            {/*<img className="logo2" src={LoganImage} width="100px"/>*/}
                            {/*<img className="logo1" src={IrespLogo} width="200px"/>*/}
                        </div>
                        <div className="login-title">
                            <span>{t(`Sign-in`)}</span>
                        </div>
                        <div className="login-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSignIn();
                            }}>
                                <div className="group-label pt15"><span>{t(`Enter credentials to login`)}</span></div>
                                <div className="pt10">
                                    {UsernameInput}
                                </div>
                                <div className="pb5">
                                    <OACPasswordField
                                        placeholder="Enter Password"
                                        value={state.password}
                                        onChange={(val) => setState({...state, password: val})}/>
                                </div>
                            </form>
                        </div>
                        <div className="login-footer">
                            <div>
                                <button className="invisible" onClick={() => {
                                    handleForgot()
                                }}>{t(`Forgot Password`)}</button>
                                <button onClick={() => {
                                    handleSignIn()
                                }}>{t(`Sign-in`)}</button>
                            </div>
                        </div>
                    </div>
                    : undefined
            }
            {
                state.isForgotPassword && state.step === 1
                    ? <div id="login-form">
                        <div className="login-header">
                            <img className="logo1" src={AppIcon}/>
                        </div>
                        <div className="login-title">
                            <span>{t(`Forgot password`)}</span>
                        </div>
                        <div className="login-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSignIn();
                            }}>
                                <div className="group-label"><span>{t(`Select role to login in:`)}</span></div>
                                <div>
                                    <EspDropdown
                                        key={Math.random()}
                                        value={state.role}
                                        placeholder={`Select Role`}
                                        options={props.roles?.map(b => ({value: b, label: ROLES_NAME_MAP[b]}))}
                                        // noOptionsMessage={}
                                        onChange={(val) => setState({...state, role: val})}
                                    />
                                </div>

                                <div className="group-label"><span>{t(`Enter email address:`)}</span></div>
                                <div>
                                    {EmailInput}
                                </div>
                                {/*<button className="d-none" type="submit"></button>*/}
                            </form>
                        </div>
                        <div className="login-footer">
                            <div>
                                {/*<div className="forget" onClick={()=>{}}>Forgot password</div>*/}
                                <button className={cx(
                                    "proceed",
                                    !state.email ? "disabled" : ""
                                )} onClick={() => {
                                    handleForgotStep1()
                                }}>{t(`Proceed`)}</button>
                            </div>
                        </div>
                    </div>
                    : undefined
            }
            {
                state.isForgotPassword && state.step === 2
                    ? <div id="login-form">
                        <div className="login-header">
                            <img className="logo1" src={AppIcon}/>
                        </div>
                        <div className="login-title">
                            <span>{t(`Email sent`)}</span>
                        </div>
                        <div className="login-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSignIn();
                            }}>
                                <div className="mt40">
                                    {t(`An email to reset your password has been sent to the registered email address of`)}
                                    <b>&nbsp;{state.role?.label}.</b>
                                </div>
                                <div className="mt10">
                                    {t(`Please click on the link in the email and follow the instructions to reset your password.`)}
                                </div>
                            </form>
                        </div>
                        <div className="login-footer">
                            <div>
                                <button className={cx(
                                    "proceed done",
                                )} onClick={() => {
                                    setState({...initialState})
                                }}>{t(`OK`)}</button>
                            </div>
                        </div>
                    </div>
                    : undefined
            }
            {
                props.error
                    ? <div className="login-error">
                        {t(`${props.error}`)}
                    </div>
                    : null
            }
            <a id="link-hidden" onClick={handleSignIn}></a>
            {
                props.isDuplicatedLogin
                    ? <IrespModal
                        clazz="duplicate-login-modal"
                        title={t(`Duplicate sign-in attempt`)}
                        contents={[
                            <div>
                                {t(`This will sign you out on other devices and you may lose any unsaved edits.`)}
                            </div>,
                        ]}
                        buttons={[
                            <button onClick={handleDuplicateLoginCancel}>{t(`Cancel`)}</button>,
                            <button onClick={handleDuplicateLoginConfirm}>{t(`Confirm`)}</button>,
                        ]}
                    />
                    : null
            }
        </div>
    );
}