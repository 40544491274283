import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import Layout from "./components/layout";
import Login from "./components/login";

import NotFound from "./components/not-found";
import ResetForm from "./components/reset";

import CSPDashboard from "./components/csp/dashboard";
import Diagnostics from "./components/csp/diagnostics";
import Schedule from "./components/csp/schedule";

import {ROLES} from "./constants/roles";
import './App.css';
import './media.css';

function App(props) {

    // const renderOSPRoutes = () => {
    //     return <>
    //         <Route path="/osp-dashboard" name="Dashboard" component={OSPDashboard}/>
    //         <Route path="/osp-maintenance-release" name="SW/FW Release" component={Release}/>
    //     </>
    // };

    const renderCSPRoutes = () => {
        return <>
            <Route path="/csp-dashboard" name="Dashboard" component={Diagnostics}/>
            <Route path="/csp-cc-management" name="CC Management" component={CSPDashboard}/>
            {/*<Route path="/csp-maintenance-schedule" name="Schedule" component={Schedule}/>*/}
        </>
    };

    return (
        <Layout>
            <Switch>
                <Route path="/login" component={Login}/>
                <Route exact path="/reset-password" name="ResetPassword" component={ResetForm}/>
                {
                    !localStorage.getItem("token")
                        ? <Redirect exact from="/" to="/login"/>
                        : undefined
                }
                {
                    localStorage.getItem("token")
                    || localStorage.getItem("OMNI2_ROLE") === ROLES.CSP
                        ? <Redirect exact from="/" to="/csp-dashboard"/>
                        : undefined
                }
                {
                    localStorage.getItem("OMNI2_ROLE") === ROLES.CSP
                        ? renderCSPRoutes()
                        : undefined
                }
            </Switch>
            <Route path="/not-found" component={NotFound}/>
        </Layout>
    );
}

const mapStateToProps = state => ({
    isLogin: state.loginReducer.isLogin,
});

export default connect(mapStateToProps)(App);
