import {connect} from 'react-redux'
import {Dashboard} from "./Dashboard";
import {cspManagement} from "../../../redux/actions/csp/cspManagement";

const mapStateToProps = state => ({
    listOfCC: state.cspManagementReducer.listOfCC || [],
    listOfCountries: state.cspManagementReducer.listOfCountries || ["JP"],
    error: state.cspManagementReducer.error,
});

function mapDispatchToProps(dispatch) {
    return ({
        getListOfCC: () => dispatch(cspManagement.getListOfCC()),
        addCC: (cc) => dispatch(cspManagement.addCC(cc)),
        updateCC: (cc) => dispatch(cspManagement.updateCC(cc)),
        deleteCC: (cc) => dispatch(cspManagement.deleteCC(cc)),
        getListOfCountry: () => dispatch(cspManagement.getListOfCountry()),
        setError: (error) => dispatch(cspManagement.setError(error))
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);