import React, {useState} from "react";

import "./OACSearchField.css";
import {FormattedMessage} from "react-intl";
import SearchImg from "../../../images/oac/Search-Img.png"

export function OACSearchField(props) {

    return <div className="oac-search-field invisible">
        <FormattedMessage id={props.placeholder || "Search"}>
            {
                placeholder => <input className={props.inputClazz} maxLength={props.maxLength} placeholder={placeholder} type="text"
                                      value={props.value} onChange={(e) => props.onChange(e.target.value)}/>
            }
        </FormattedMessage>
        <img src={SearchImg}/>
    </div>
}