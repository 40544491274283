import {dataProvider} from "../../connections/net";

export const CHANGE_LANGUAGE = "app/action/CHANGE_LANGUAGE";

export const languageActions = {
    changeLanguage: (language) => dispatch => {
        dispatch({type: CHANGE_LANGUAGE, payload: language})
    },
    getLanguage: () => async dispatch => {
        const {status_code, language} = await dataProvider.getLanguage();
        if (status_code === 0 && language) {
            dispatch(languageActions.changeLanguage(language));
        }
    }
};