import React, {useRef, useState} from "react";

import {messageWrapper as t} from "../../../utils/message-wrapper";
import filterBlueImg from "../../../images/oac/Filter_Blue.png";
import filterGreyImg from "../../../images/oac/Filter_Grey.png";

import "./OACFilterSearch.css";
import {useOutsideDetect} from "../../../utils/hooks";
import {useIntl} from "react-intl";

export function OACFilterSearch(props) {
    const intl = useIntl();

    const [state, setState] = useState({});

    const dateRef = useRef(null);

    const selectCCPlaceholder = intl.formatMessage({ id: "Select CC" });
    const all = intl.formatMessage({ id: "All" });

    useOutsideDetect(dateRef, state.openMenu, () => setState({...state, openMenu: false}));

    const allValue = {value: undefined, label: all};

    const handleSelectOption = (selected) => {
        setState({...state, selectedCC: selected, openMenu: false, search: undefined})
    };

    const handleSearch = () => {
        if (!state.search && !state.selectedCC) {
            props.handleSearch(allValue);
        } else {
            props.handleSearch(state.search || state.selectedCC);
        }

    };

    return <div className="filter-search-combine">
        <div className="inline">
            {t(`Filter by:`)}
        </div>
        <div className="inline fsc-control">
            <span className="filter-placeholder">{t("Care Centre:")}</span>
            <input value={state.search || state.selectedCC?.label}
                   onChange={(e) => setState({...state, search: e.target.value, selectedCC: undefined})} placeholder={selectCCPlaceholder}/>
            <div className="fsc-indicator" onClick={() => setState({...state, openMenu: true})}></div>
            <div className={state.openMenu ? "fsc-menu show" : "fsc-menu"} ref={dateRef}>
                {
                    state.openMenu
                        ? [
                            <div className="fsc-option" onClick={() => handleSelectOption(allValue)}>{t(`All`)}</div>,
                            ...props.listOfCC?.map(n => <div className="fsc-option" onClick={() => handleSelectOption({
                                value: n.oah_id,
                                label: n.display_name
                            })}>{n.display_name}</div>)
                        ]
                        : undefined
                }
            </div>
        </div>
        <div className="inline">
            {
                // state.selectedCC || state.search
                true
                    ? <img src={filterBlueImg} onClick={() => handleSearch()}/>
                    : <img src={filterGreyImg}/>
            }
        </div>
    </div>
}