import {GET_DATA, GET_STATUS_DATA} from "../actions/dashboard";

const initialState = {};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DATA:
            const {patients, beds, nurses} = action.payload;
            const bedAssignmentMap = {};
            const nurseMap = {};

            beds.map(b => {
                bedAssignmentMap[b.patient_id] = b.bed_name;
            });

            // extract patient map
            const patientMap = {};
            patients.forEach(p => patientMap[p.id]= {name: p.name, enroll: p.enroll, discharged: p.discharged});

            // extract nurse map
            nurses.forEach(n => nurseMap[n.id] = n.name);

            return {
                ...state,
                patients,
                nurses,
                nurseMap,
                bedAssignmentMap,
                beds: beds
                    .filter(b => b.patient_id && b.patient_id !== "" && !patientMap[b.patient_id].discharged)
                    .map(b => ({...b, patient_name: patientMap[b.patient_id]?.name, enroll: patientMap[b.patient_id]?.enroll})),
                patientMap
            };
        case GET_STATUS_DATA:
            return {...state, beds: action.payload?.filter(b => b.patient_name && b.patient_name !== "")};
        default:
            return state
    }
};

export default dashboardReducer;