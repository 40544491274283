import React, {useEffect, useState} from "react";
import {useAuth} from "../../../connections/net";
import {messageWrapper as t} from "../../../utils/message-wrapper";
import "./Schedule.css";
import {OACSearchField} from "../../common/oac/OACSearchField";
import {EspTable} from "../../common/EspTable";
import moment from "moment";
import {formatDisplayDateTime} from "../../../utils/format";
import {OACSortIcon} from "../../common/oac/OACSortIcon";
import {IrespModal} from "../../common/IrespModal";
import {OACPagingSelection} from "../../common/oac/OACPagingSelection";
import {OACPaging} from "../../common/oac/OACPaging";
import TimePicker from "rc-time-picker";
import {InputDatePicker} from "../../common/InputDatePicker";
import {useIntl} from "react-intl";
import {formatNAValue} from "../../../utils/formatNAValue";

export function Schedule(props) {

    useAuth();

    const [state, setState] = useState({tab: 1, patients: [], page: 1, itemsPerPage: 10, schedule_date: moment().add(0, "days").toDate(), schedule_time: moment(), sortOrder: 1, sortField: "oah_id"});

    const intl = useIntl();

    const STATUS_MAP = {
        "Scheduled": intl.formatMessage({ id: "Scheduled"}),
        "Pending Activation": intl.formatMessage({ id: "Pending Activation"}),
        "n/a": intl.formatMessage({ id: "n/a"}),
        "Update not enabled": intl.formatMessage({ id: "Update not enabled"}),
    };

    useEffect(() => {
        props.listSchedule();
        props.getListOfCC();
    }, []);

    useEffect(() => {
        if (props.listOfSchedule && props.listOfSchedule.length > 0) {
            const filterBed = [...props.listOfSchedule]
                .filter(b => {
                    return true;
                })
                .map(a => {
                    return {...a, epoch: a.schedule?.cdmcu?.epoch || a.schedule?.smcu?.epoch}
                })
                .sort((a, b) => {
                    const special_fields = ["cdmcu", "smcu", "cdmcu_target", "smcu_target", "epoch", "bed_name"];

                    if (special_fields.indexOf(state.sortField) > -1) {
                        if (a.oah_id === b.oah_id) return state.sortOrder * (a[state.sortField] <= b[state.sortField] ? 1 : -1);
                        return state.sortOrder * (a.oah_id <= b.oah_id ? 1 : -1);
                    }

                    let dataOrder = 0;
                    let sortField = state.sortField;

                    if(!sortField) {
                        sortField = "oah_id";
                    }

                    // console.log(sortField)
                    // console.log(dataOrder, a[sortField], b[sortField])

                    if (!a[sortField]) dataOrder = 1;
                    else if (!b[sortField]) dataOrder = -1;
                    else dataOrder = a[sortField] <= b[sortField] ? 1 : -1;
                    return dataOrder * state.sortOrder;
                });


            const itemsThisPage = [...filterBed].splice((state.page - 1) * state.itemsPerPage, state.itemsPerPage);
            setState({...state, filterBed: filterBed, itemsThisPage});
        }
    }, [props.listOfSchedule, state.page, state.itemsPerPage, state.sortOrder, state.sortField]);

    const generateScheduleTable = () => {
        const generateHeader = () => {
            return <tr>
                <td>
                    {t(`Care Centre Name`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "oah_name")}/>
                </td>
                <td>
                    {t(`CC ID`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "oah_id")}/>
                </td>
                <td>
                    {t(`Bed Name`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "bed_name")}/>
                </td>
                <td>
                    {t(`BCU FW Ver.`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "cdmcu")}/>
                </td>
                <td>
                    {t(`BCU Update Ver.`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "cdmcu_target")}/>
                </td>
                <td>
                    {t(`Sensor FW Ver.`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "smcu")}/>
                </td>
                <td>
                    {t(`Sensor Update Ver.`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "smcu_target")}/>
                </td>
                <td>
                    {t(`Schedule for FW update`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "epoch")}/>
                </td>
                <td>
                    {t(`Update Status`)}
                    <OACSortIcon handleSort={(order) => handleSort(order, "epoch")}/>
                </td>
                <td>
                    {t(`FW Update Action`)}
                </td>
            </tr>
        };

        const generateProcessAction = (status, oah_id, oah_name) => {
            if (status === STATUS_MAP.Scheduled) {
                return <span>
                         <a className="link" onClick={() => handleRemove(oah_id)}>{t(`Cancel`)}</a>
                    </span>
            } else if (status === STATUS_MAP["Pending Activation"]) {
                return <span>
                     <a className="link"
                        onClick={() => setState({
                            ...state,
                            update: oah_id,
                            updateName: oah_name,
                            isOpenProceedModal: true
                        })}>{t(`Proceed`)}</a>
                </span>;
            }

            return <span>
                     <a className="link disabled"
                        onClick={() => {
                        }}>{t(`Proceed`)}</a>
                </span>;
        };

        const statusMap = {};
        const scheduleMap = {};
        const getUpdateStatus = (oah_id) => {
            const listOfSchedule = state.itemsThisPage?.filter(i => {
                return i.oah_id === oah_id && i.schedule && ((i.schedule.smcu && i.smcu_enabled) || (i.schedule.cdmcu && i.cdmcu_enabled));
            });
            const listOfUpdate = state.itemsThisPage?.filter(i => {
                return i.oah_id === oah_id && ((i.cdmcu_enabled && i.cdmcu !== i.cdmcu_target) || (i.smcu_enabled && i.smcu !== i.smcu_target))
            });

            if (listOfSchedule.length > 0) {
                statusMap[oah_id] = STATUS_MAP.Scheduled;
                scheduleMap[oah_id] = listOfSchedule[0].schedule?.cdmcu?.epoch || listOfSchedule[0].schedule?.smcu?.epoch;
            } else if (listOfUpdate.length > 0) {
                statusMap[oah_id] = STATUS_MAP["Pending Activation"];
            } else {
                statusMap[oah_id] = STATUS_MAP["n/a"];
            }
        };


        const generateBody = () => {
            if (!state.itemsThisPage || state.itemsThisPage.length < 1) {
                return <tr>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                // length
                const rowSpan = {};
                state.itemsThisPage
                // .sort((a, b) => a.sys_id > b.sys_id ? 1 : -1)
                    .forEach(cc => {
                        rowSpan[cc.oah_id] = !rowSpan[cc.oah_id] ? 1 : rowSpan[cc.oah_id] + 1;
                    });

                // rowSpanned
                const rowSpanning = {};

                // map status
                Object.keys(rowSpan).forEach(oah_id => {
                    getUpdateStatus(oah_id);
                });

                return state.itemsThisPage
                    // .sort((a, b) => compareOACBedName(a.oah_id, b.oah_id))
                    .map((b, idx) => {
                        // spanning
                        const td1Span = !rowSpanning[b.oah_id] ?
                            <td rowSpan={rowSpan[b.oah_id]}>{scheduleMap[b.oah_id] ? formatDisplayDateTime(scheduleMap[b.oah_id]) : STATUS_MAP["Update not enabled"]}</td> : undefined;
                        const td3Span = !rowSpanning[b.oah_id] ?
                            <td rowSpan={rowSpan[b.oah_id]}>{statusMap[b.oah_id]}</td> : undefined;
                        const td2Span = !rowSpanning[b.oah_id] ?
                            <td rowSpan={rowSpan[b.oah_id]}>{generateProcessAction(statusMap[b.oah_id], b.oah_id)}</td> : undefined;
                        rowSpanning[b.oah_id] = true;

                        return <tr>
                            <td>{props.cc_map[b.oah_id]}</td>
                            <td>{b.oah_id}</td>
                            <td>{b.bed_name}</td>
                            <td>{formatNAValue(b.cdmcu)}</td>
                            <td>{formatNAValue(b.cdmcu_enabled && b.cdmcu !== b.cdmcu_target ? b.cdmcu_target : "--")}</td>
                            <td>{formatNAValue(b.smcu)}</td>
                            <td>{formatNAValue(b.smcu_enabled && b.smcu !== b.smcu_target ? b.smcu_target : "--")}</td>
                            {td1Span}
                            {td3Span}
                            {td2Span}
                        </tr>
                    });
            }
        };

        return <>
            <div className="table-descr">
                <div className="inline w780">
                    {t(`Display`)}
                    &nbsp;<OACPagingSelection handleChangePage={handleChangePage}/>&nbsp;
                    {t(`records per page`)}
                </div>
                <div className="inline w19p">
                </div>
                <div className="inline"></div>
                <div className="inline w50p right">
                    <span className="link">
                        <OACSearchField inputClazz="w325px"
                                        onChange={(e) => setState({...state, search: e.target?.value})}/>
                    </span>
                </div>
            </div>
            <EspTable className="result" renderHeader={generateHeader}
                      renderBody={generateBody}/>
            <OACPaging
                total={state.filterBed?.length}
                itemsPerPage={state.itemsPerPage}
                page={state.page}
                setPage={(page) => setState({...state, page: page})}
            />
        </>
    };

    const handleProceedScheduleCancel = () => {
        setState({...state, isOpenProceedModal: false, error: undefined});
    };

    const handleProceedScheduleConfirm = async () => {
        const {schedule_time, schedule_date} = state;

        const scheduleSelected = moment(schedule_date).hour(schedule_time.hours()).minute(schedule_time.minute());

        const diff = scheduleSelected.diff(moment());

        // if (diff < (24 * 3600 * 1000)) {
        //     setState({...state, error: "It must be at least 24 hours after the current time"});
        //     return;
        // }

        if (diff < (10 * 60 * 1000)) {
            setState({...state, error: "It must be at least 24 hours after the current time"});
            return;
        }

        let res = true;

        let isFail;
        state.filterBed
            .filter(bed => {
                return bed.mac && bed.oah_id === state.update
            })
            .map(async bed => {
                if (bed?.smcu_enabled && bed?.smcu !== bed?.smcu_target && bed?.smcu_target !== "--") {
                    res = await props.updateSchedule({
                        "mac": bed.mac,
                        "epoch": Number(scheduleSelected.format('x')),
                        // "epoch" : 1682380800000,
                        "firmware_type": "smcu",
                        "firmware_version": bed?.smcu_target
                    });

                    if(!res) isFail = true;
                }

                if (bed?.cdmcu_enabled && bed?.cdmcu !== bed?.cdmcu_target && bed?.cdmcu_target !== "--") {
                    res = await props.updateSchedule({
                        "mac": bed.mac,
                        "epoch": Number(scheduleSelected.format('x')),
                        // "epoch" : 1682380800000,
                        "firmware_type": "cdmcu",
                        "firmware_version": bed?.cdmcu_target
                    });

                    if(!res) isFail = true;
                }
            });

        // TODO handle error
        if (!isFail) {
            setState({...state, isOpenProceedModal: false, error: undefined});
        } else {
            setState({...state, error: "Error encountered"});
        }
    };

    const handleRemove = (oah_id) => {
        let res = true;

        state.filterBed
            .filter(bed => {
                return bed.mac && bed.oah_id === oah_id
            })
            .map(async bed => {
                if (bed?.schedule && bed?.schedule?.smcu) {
                    res = await props.removeUpdatedSchedule({
                        "mac": bed.mac,
                        "firmware_type": "smcu"
                    });
                }

                if (bed?.schedule && bed?.schedule?.cdmcu) {
                    res = await props.removeUpdatedSchedule({
                        "mac": bed.mac,
                        "firmware_type": "cdmcu"
                    });
                }
            });
    };

    const handleChangePage = (itemsPerPage) => {
        setState({...state, itemsPerPage: itemsPerPage})
    };

    const handleSort = (order, field) => {
        setState({...state, sortOrder: order, sortField: field});
    };

    const disabledHours = () => {
        const hours = [];
        if(moment(state.schedule_date).format("D") === moment().add(0, "days").format("D")) {
            const untilHour = moment().format("HH");
            for(let i = 0; i < Number(untilHour); i++) {
                hours.push(i);
            }
        }
        return hours;
    };

    const disabledMinutes = () => {
        const minutes = [];
        if(moment(state.schedule_date).format("D") === moment().add(0, "days").format("D") && moment(state.schedule_time).format("H") === moment().format("H")) {
            const untilMinute = Number(moment().format("mm"));
            for(let i = 0; i <= untilMinute + 10; i++) {
                minutes.push(i);
            }
        }
        return minutes;
    };

    return (
        <div className="scheduling">
            <div className="data">
                <div className="tabs">
                    <a onClick={() => setState({...state, tab: 1, filterField: undefined})}
                       className={state.tab === 1 ? "selected" : ""}>{t(`Updates Scheduling`)}</a>
                    {/*<a onClick={() => setState({...state, tab: 2, filterField: undefined})}*/}
                    {/*className={state.tab === 2 ? "selected" : ""}>{t(`Installed Mats`)}</a>*/}
                </div>
                <div className="devider"></div>
            </div>
            {
                generateScheduleTable()
            }

            {
                state.isOpenProceedModal
                    ? <IrespModal
                        clazz="proceed-schedule-modal"
                        title={t(`Firmware Update`)}
                        contents={[
                            <div className="">
                                <div className="inline w160px">
                                    {t(`Care Centre:`)}
                                </div>
                                <div className="inline">
                                    <b> {props.cc_map[state.update]} </b>
                                </div>
                            </div>,
                            <div className="">
                                <div className="inline w160px">
                                    {t(`Schedule:`)}
                                </div>
                                <div className="inline">
                                    <div className="schedule-picker">
                                        <InputDatePicker
                                            hideImage={true}
                                            value={state.schedule_date}
                                            minDate={moment().add(0, "days")}
                                            showAll={true}
                                            className="date"
                                            onChange={(v) => setState({
                                                ...state,
                                                schedule_date: v
                                            })}/>&nbsp;:&nbsp;
                                        <TimePicker
                                            getPopupContainer={triggerNode => triggerNode.parentNode}
                                            format={"HH:mm"}
                                            showSecond={false}
                                            allowEmpty={false}
                                            inputReadOnly={true}
                                            value={state.schedule_time}
                                            disabledHours={disabledHours}
                                            disabledMinutes={disabledMinutes}
                                            onChange={(v) => setState({
                                                ...state,
                                                schedule_time: v
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>,
                            <div className="mt50">
                                {t(`Click Confirm to proceed.`)}
                            </div>,
                            <div className="error">
                                {
                                    state.error
                                        ? <div>{t(state.error)}</div>
                                        : undefined
                                }
                            </div>,
                        ]}
                        buttons={[
                            <button onClick={handleProceedScheduleCancel}>{t(`Cancel`)}</button>,
                            <button onClick={handleProceedScheduleConfirm}>{t(`Confirm`)}</button>,
                        ]}
                    />
                    : null
            }
        </div>
    );
}