import {compareOACBedName} from "../../../utils/sort";
import {GET_CC_LIST, GET_COUNTRY_LIST, SET_ERROR} from "../../actions/csp/cspManagement";

const initialState = {};

const cspManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CC_LIST:
            const oahs = action.payload;
            const cc_map = {};
            oahs.forEach(c => {
                cc_map[c.oah_id] = c.display_name;
            });
            return {...state, listOfCC: oahs.sort((a, b) => compareOACBedName(a.display_name, b.display_name)), cc_map};
        case SET_ERROR:
            return {...state, error: action.payload};
        case GET_COUNTRY_LIST:
            const countries = action.payload;
            return {
                ...state,
                listOfCountries: countries.sort((a, b) => compareOACBedName(a.country_code, b.country_code)),
            };
        default:
            return state
    }
};

export default cspManagementReducer;