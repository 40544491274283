import {dataProvider} from "../../connections/net";
import {appAction} from "./appAction";

export const SET_ERROR = "liveTile/action/SET_ERROR";
export const POLLING_DATA = "liveTile/action/POLLING_DATA";
export const SET_STATE = "liveTile/action/SET_STATE";
export const CLEAR_DATA = "liveTile/action/CLEAR_DATA";

export const liveTileAction = {
    pollingData: () => async dispatch => {
        try {
            const {status_code, tiles, patients, nurses} = await dataProvider.pollingData();
            if (status_code === 0 && tiles) {
                dispatch({type: POLLING_DATA, payload: {tiles, patients, nurses}});
                dispatch(appAction.reconnect(false, true));
            } else {
                dispatch(appAction.reconnect(true));
            }
        } catch (e) {
            console.log("Error: ", e.message);
            if (e.message === "Disconnect") {
                dispatch(appAction.reconnect(true));
            } else if(e.message === "Unauthorize") {
                dispatch(appAction.showLogoutModal());
            }
            dispatch(appAction.closeLoading());
            dispatch({type: POLLING_DATA, payload: undefined});
        }
    },
    turnOffSoundForTile: (info) => async dispatch => {
        const {status_code} = await dataProvider.turnOffSoundForTile(info);
        if (status_code === 0) {
            // dispatch({type: POLLING_DATA, payload: {tiles, patients, nurses}});
        }
    },
    setError: (error) => dispatch => dispatch({type: SET_ERROR, payload: error}),
    setState: (state) => dispatch => dispatch({type: SET_STATE, payload: state}),
    clearData: () => dispatch => dispatch({type: CLEAR_DATA})
};