import React, {useState} from "react";

import {messageWrapper as t} from "../../../utils/message-wrapper";
import {EspDropdown} from "../EspDropdown";
import filterBlueImg from "../../../images/oac/Filter_Blue.png";
import filterGreyImg from "../../../images/oac/Filter_Grey.png";

import "./OACFilter.css";

export function OACFilter(props) {

    const [state, setState] = useState({});

    const handleSelect = (selected) => {
        setState({...state, selectedCountry: selected});
    };

    const handleCSPSelect = (selected) => {
        setState({...state, selectedCSP: selected});
    };

    const handleCCSelect = (selected) => {
        setState({...state, selectedCC: selected});
    };

    const options = props.hasAllOption ? [{value: undefined, label: "All"}] : [];

    return <div className="filter-wrapper">
        <div className="inline">
            {t(`Filter by:`)}
        </div>
        <div className="inline">
            <span className="filter-placeholder">{t("Care Centre:")}</span>
            <EspDropdown
                key={Math.random()}
                value={state.selectedCC}
                placeholder={`Select CC`}
                options={[
                    ...options,
                    ...props.listOfCC?.map(n => ({
                            value: n.oah_id,
                            label: n.display_name
                        }))
                ]}
                onChange={handleCCSelect}
            />
        </div>
        <div className="inline">
            {
                state.selectedCC
                    ? <img src={filterBlueImg} onClick={() => props.handleSearch(state.selectedCC)}/>
                    : <img src={filterGreyImg}/>
            }
        </div>
    </div>
}