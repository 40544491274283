import React from "react";
import cx from "classnames";
import GwTabletSpinner from "../../images/gw_tablet_spinner.gif";

export function Loading(props) {
    if (props.type === "loading") {
        return (
            <div className={props.className}>
                <img src={GwTabletSpinner}/>
            </div>
        )
    }
    return (
        <div className={cx("esp-loading", !props.loading ? "d-none" : "")}>
            {/*<img className="spinner" src={spinnerImage} alt="spinner"/>*/}
            {/*<Spinner className="spinner" style={{ width: '3rem', height: '3rem' }} />*/}
            <div className="loading-img-wrapper">
                <img className="gw-spinner" src={GwTabletSpinner}/>
            </div>
        </div>
    )
}