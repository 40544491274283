import React, {useState} from "react";

import {messageWrapper as t} from "../../../utils/message-wrapper";
import {EspDropdown} from "../EspDropdown";
import filterBlueImg from "../../../images/oac/Filter_Blue.png";
import filterGreyImg from "../../../images/oac/Filter_Grey.png";

import "./OACFilter.css";

export function OACFilterCC(props) {

    const [state, setState] = useState({});

    const handleCCSelect = (selected) => {
        setState({...state, selectedCC: selected});
    };

    return <div className="filter-wrapper">
        <div className="inline">
            {t(`Select Care Centre:`)}
        </div>
        <div className="inline filter-border-wrapper">
            <span>
                {
                    !state.selectedCC
                        ? <span className="cc-id grey">{t(`Select Care Centre`)}</span>
                        : <span className="cc-id grey">{state.selectedCC?.value}</span>
                }
            </span>
            <EspDropdown
                key={Math.random()}
                value={state.selectedCC}
                placeholder={`Select Care Centre`}
                hidePlacholder={true}
                options={[
                    ...props.listOfCC?.map(n => ({
                            value: n.oah_id,
                            label: n.display_name
                        }))
                ]}
                onChange={handleCCSelect}
            />
        </div>
        <div className="inline">
            {
                state.selectedCC
                    ? <img src={filterBlueImg} onClick={() => props.handleSearch(state.selectedCC)}/>
                    : <img src={filterGreyImg}/>
            }
        </div>
    </div>
}