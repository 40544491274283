import {connect} from 'react-redux'
import {NotFound} from "./NotFound";

const mapStateToProps = state => ({
});

function mapDispatchToProps(dispatch) {
    return ({
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);