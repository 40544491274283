import {GET_BEDS, GET_HISTORY, SET_ERROR} from "../actions/transferPatientAction";

const initialState = {};

const transferPatientReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BEDS:
            const {beds, patients} = action.payload;
            const availableBeds = beds.filter(b => b.patient_id === null || b.patient_id === "");
            // filter assgined/unassigned beds
            const assignedBeds = beds.filter(b => b.patient_id !== null && b.patient_id !== "");
            const unassignedBeds = beds.filter(b => b.patient_id !== null && b.patient_id !== "");

            // extract patient map
            const patientMap = {};
            patients.map(p => patientMap[p.id]= p.name);

            return {...state, assignedBeds, unassignedBeds, patientMap, availableBeds};
        case GET_HISTORY:
            const history = action.payload.sort((a, b) => a.transfer_datetime < b.transfer_datetime ? 1 : -1);
            return {...state, history};
        case SET_ERROR:
            return {...state, error: action.payload};
        default:
            return state
    }
};

export default transferPatientReducer;