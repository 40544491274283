import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import enMessages from '../../translations/en.json';
import jpMessages from '../../translations/jp.json';

const messages = {
    en:  enMessages,
    ja: jpMessages,
};

const specialLocale = {
    // "zh-Hans": "zh-CN",
    // "zh-Hant": "zh-CN",
};

export function LanguageProvider(props) {
    const special = specialLocale[props.locale];
    return (
        <IntlProvider
            locale={special || props.locale}
            messages={messages[props.locale]}
        >
            {React.Children.only(props.children)}
        </IntlProvider>
    );
}

LanguageProvider.propTypes = {
    locale: PropTypes.string,
    messages: PropTypes.object,
    children: PropTypes.element.isRequired,
};

const mapStateToProps = state => ({
    locale: state.languageReducer.locale,
});

export default connect(mapStateToProps)(LanguageProvider);